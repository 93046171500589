import styled from 'styled-components';

const colorMapForOrderStatuses = {
  new: 'green',
  ready: 'yellow',
  canceled: 'red',
  shipped: 'transparent',
};

export const StyledUpsStatusCell = styled.div`
  width: 150px;
  height: 96px;
  margin: -16px;
  padding-top: 37px;
  background-color: ${({ orderStatus }) =>
    colorMapForOrderStatuses[orderStatus]};
  text-align: center;
`;
