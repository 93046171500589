import styled from 'styled-components';

const colorMapPromoStatuses = {
  active: 'green',
  inactive: 'red',
};

export const StyledPromoStatusCell = styled.div`
  width: 150px;
  height: 96px;
  margin: -16px;
  padding-top: 37px;
  color: ${({ status }) => colorMapPromoStatuses[status]};
  font-weight: bold;
  text-align: center;
`;
