import styled from 'styled-components';

export const StyledLogoWrapper = styled.div`
  padding: ${({ size }) => (size === 'large' ? '25px' : '5px')};
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: ${({ size }) => (size === 'large' ? '40px' : '24px')};
  text-transform: uppercase;
`;
