import React from 'react';

import { StyledPaymentStatusCell } from './styled';

const PaymentStatusCell = ({ status }) => {
  const map = {
  'paid': 'Paid' ,
  'failed': 'Failed' ,
  'customerbehalf': 'On Customer Behalf' ,
  'customerbehalfconfirmed': 'On Customer Behalf Confirmed' ,
  'customerbehalfpaid': 'On Customer Behalf Paid' ,
  }
  return (
  <StyledPaymentStatusCell status={status}>{map[status]}</StyledPaymentStatusCell>
)
};

export default PaymentStatusCell;
