import moment from 'moment';

class Discount {
  code = '';
  expirationDate = null;
  id = '';
  isActive = false;
  isDeleted = false;
  marketplaceAmount = 0;
  promoType = '';
  sellerAmount = 0;
  type = ''

  constructor(discount, rootStore) {
    Object.assign(this, discount);
    this.rootStore = rootStore;

    this.formatExpirationDate();
  }

  formatExpirationDate() {
    this.expirationDate = moment(this.expirationDate).format("DD/MMM/YYYY");
  }
}

export default Discount;
