import { action, observable, computed } from 'mobx';
import { ExportToCsv } from 'export-to-csv';

import { optionsCSV } from '../pages/reports/settings';

class Reports {
  @observable rawData = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get data() {
    return this.rawData;
  }

  @action.bound onFetchAll(data) {
    this.rawData = data;
  }

  @action.bound async fetchAll(destination, dateFrom, dateTo) {
    const {
      getAll: { method, url },
    } = this.rootStore.urls.reports;

    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(destination, dateFrom, dateTo)
    );
  }

  @action.bound onExport(data) {
    const csvExporter = new ExportToCsv(optionsCSV);

    csvExporter.generateCsv(
      data.map(e => {
        return {
          Destination: e.country,
          StillWine: e.totalStillPrice,
          TotalBottles_still: e.totalStillBottles,
          SparklingWine: e.totalSparklingPrice,
          TotalBottles_sprkling: e.totalSparklingBottles,
          Delivery: e.delivery,
          Amount: e.allTotal,
        }
      })
    );
  }

  @action.bound async exportCSV(destination, dateFrom, dateTo) {
    const {
      getAll: { method, url },
    } = this.rootStore.urls.reports;

    return await this.rootStore.makeRequest(
      this.onExport,
      method,
      url(destination, dateFrom, dateTo)
    );
  }
}

export default Reports;
