import { action, observable, computed } from 'mobx';
import { ExportToCsv } from 'export-to-csv';

import { optionsCSV } from '../pages/orders/settings';

class Orders {
  @observable rawOrders = [];
  @observable rawOrder = {};
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get orders() {
    return this.rawOrders;
  }

  @computed
  get order() {
    return this.rawOrder;
  }

  @action.bound findOne(id) {
    return this.boundedFindOne.bind(this, id);
  }

  @action.bound async boundedFindOne(id) {
    this.rawOrder = this.rawOrders.find(order => order.id === id);
  }

  @action.bound onCancelOrder(orderId) {
    return () => {
      this.rawOrders = this.rawOrders.map(order => {
        if (order.id === orderId) {
          return { ...order, orderStatus: 'canceled' };
        }

        return order;
      });
    };
  }

  @action.bound cancelOrder(orderId) {
    return this.boundedCancelOrder.bind(this, orderId);
  }
  @action.bound async boundedCancelOrder(orderId) {
    const {
      cancelOrder: { method, url },
    } = this.rootStore.urls.orders;

    return this.rootStore.makeRequest(
      this.onCancelOrder(orderId),
      method,
      url(orderId)
    );
  }

  @action.bound onConfirmOrder(orderId) {
    return () => {
      this.rawOrders = this.rawOrders.map(order => {
        if (order.id === orderId) {
          return {
            ...order,
            orderStatus: 'in progress',
            paymentStatus: 'customerbehalfconfirmed',
          };
        }

        return order;
      });
    };
  }

  @action.bound confirmOrder(orderId) {
    return this.boundedConfirmOrder.bind(this, orderId);
  }

  @action.bound async boundedConfirmOrder(orderId) {
    const {
      confirmOrder: { method, url },
    } = this.rootStore.urls.orders;
    return this.rootStore.makeRequest(
      this.onConfirmOrder(orderId),
      method,
      url(orderId)
    );
  }

  @action.bound downloadOrderDocuments(orderId) {
    return this.boundedDownloadOrderDocuments.bind(this, orderId);
  }

  @action.bound generateInvoices(orderId, sendMail) {
    return this.boundedGenerateInvoices.bind(this, orderId, sendMail);
  }

  @action.bound onDownloadDocuments(body) {
    const blob = new Blob([body], { type: 'application/zip' });
    const fileTagUrl = window.URL.createObjectURL(blob);
    const fileTag = document.createElement('a');
    fileTag.setAttribute('hidden', true);
    fileTag.setAttribute('href', fileTagUrl);
    fileTag.setAttribute('download', 'download.zip');
    document.body.appendChild(fileTag);
    fileTag.click();
    document.body.removeChild(fileTag);
  }

  @action.bound async boundedDownloadOrderDocuments(orderId) {
    const { url, method } = this.rootStore.urls.orders.downloadOrderDocuments;

    return this.rootStore.makeRequest(
      this.onDownloadDocuments,
      method,
      url(orderId),
      {},
      {},
      'blob'
    );
  }

  @action.bound async boundedGenerateInvoices(orderId, sendMail) {
    const { url, method } = this.rootStore.urls.orders.generateInvoices;

    return this.rootStore.makeRequest(
      this.onDownloadDocuments,
      method,
      url(orderId, sendMail),
      {},
      {},
      'blob'
    );
  }

  @action.bound onFetchAll(data) {
    this.rawOrders = data.raw;
    this.total = data.total;
  }

  @action.bound async fetchAll(dateFrom, dateTo) {
    const {
      getAll: { method, url },
    } = this.rootStore.urls.orders;

    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(15, dateFrom, dateTo)
    );
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLowerCase();
    this.searchIn = field;
    return await this.searchOrders(this.searchIn, this.searchValue);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';
    return await this.fetchAll('all', 'all');
  }

  @action.bound onChangePagination(data) {
    this.rawOrders = data.raw;
    this.total = data.total;
  }

  @action.bound async changePagination(page, pageSize, dateFrom, dateTo) {
    if (!this.searchValue || !this.searchIn) {
      const { method, url } = this.rootStore.urls.orders.getPageData;
      return await this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize, dateFrom, dateTo)
      );
    }

    return await this.searchOrders(this.searchIn, this.searchValue, page);
  }

  async searchOrders(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.orders.search;
    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, 15, page)
    );
  }

  @action.bound onExport(data) {
    const csvExporter = new ExportToCsv(optionsCSV);

    csvExporter.generateCsv(
      data.map(e => {
        return {
          'Created At': e.createdAt,
          'Order No': e.orderNo,
          'Origin country': e.originCountry,
          Region: e.Region,
          'Country destination': e.countryDestination,
          'Registered estate name': e.registeredEstateName,
          'Total price': e.totalPrice,
          'Payment to estate': e.paymentToEstate,
          VAT: e.vat,
          'Collected by WJ': e.collectedByWJ,
          'Items amount': e.itemsAmount,
          'Wine amount': e.wineAmount,
          'Sparkling amount': e.sparklingAmount,
          'Order status': e.orderStatus,
          'Tracking numbers': e.trackingNumbers,
          'Pickup request number': e.pickupRequestNumber,
          'Stripe transaction ID': e.stripeTransactionId,
          'Proforma invoice No': e.proformaInvoiceNo,
          'Buyer name': e.buyerName,
          'Buyer email': e.buyerEmail,
          'Private buyer invoice No': e.privateBuyerInvoiceNo,
          Details: e.details,
        };
      })
    );
  }

  @action.bound async exportCSV(dateFrom, dateTo) {
    const { method, url } = this.rootStore.urls.orders.getOrdersExport;
    return await this.rootStore.makeRequest(
      this.onExport,
      method,
      url(dateFrom, dateTo)
    );
  }

  @action.bound onOrderNotesUpdate(responseData) {
    return responseData;
  }

  @action.bound onRequestOrderPickUp(orderId) {
    return data => {
      this.rawOrders = this.rawOrders.map(order => {
        if (order.id === orderId) {
          order.pickupRequestNumber = data.order.pickupRequestNumber;
        }

        return order;
      });
    };
  }

  @action.bound async requestOrderPickUp(orderId, body) {
    const { method, url } = this.rootStore.urls.orders.setOrderPickUp;

    return this.rootStore.makeRequest(
      this.onRequestOrderPickUp(orderId),
      method,
      url(orderId),
      body
    );
  }

  @action.bound async updateOrderNotes(orderId, notes) {
    const { method, url } = this.rootStore.urls.orders.updateOrderNotes;
    return this.rootStore.makeRequest(
      this.onOrderNotesUpdate,
      method,
      url(orderId),
      { notes }
    );
  }
}

export default Orders;
