import React, { Fragment } from 'react';
import { message } from 'antd';

import { StyledUpload, StyledImage } from './styled';
import UploadButton from './upload-button';
import DeleteButton from './delete-button';
import {
  jpegMimeType,
  pngMimeType,
  maxFileSize,
  fileSizeError,
  fileTypeError,
  listType,
  imageAlt,
} from './settings';

const Upload = ({
  title,
  styles = {},
  resourceId,
  imageUrl,
  index,
  onUpload,
  onDelete,
}) => {
  const beforeUpload = file => {
    const isJpgOrPng = file.type === jpegMimeType || file.type === pngMimeType;

    if (!isJpgOrPng) {
      message.error(fileTypeError);
    }

    const isLtM = file.size / 1024 / 1024 < maxFileSize;

    if (!isLtM) {
      message.error(fileSizeError);
    }

    return isJpgOrPng && isLtM;
  };

  const onRequest = file => onUpload(file, resourceId, index);

  const onImageDelete = async e => {
    e.stopPropagation();
    onDelete(resourceId, index);
  };

  return (
    <StyledUpload
      styles={styles}
      listType={listType}
      showUploadList={false}
      customRequest={onRequest}
      beforeUpload={beforeUpload}
    >
      {imageUrl ? (
        <Fragment>
          <StyledImage src={imageUrl} alt={imageAlt} />
          <DeleteButton onClick={onImageDelete} />
        </Fragment>
      ) : (
        <UploadButton title={title} />
      )}
    </StyledUpload>
  );
};

export default Upload;
