import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import Modal from '../../components/modal';
import Button from '../../components/button';
import { useModal } from '../../common';
import { DiscountForm } from '../../components/forms';
import { Table } from '../../components/table';
import { createButton } from '../../components/table/settings';
import { compose } from '../../services/helpers';
import { columns, tableActions, modals } from './settings';

const Discounts = ({ discountsStore }) => {
  const {
    discounts,
    discount,
    total,
    fetchAll,
    findOne,
    deleteDiscount,
    createDiscount,
    changePagination,
    updateDiscount,
    resetSearch,
  } = discountsStore;

  const [
    isCreateModalShown,
    onSetIsCreateModalShown,
    onCreateOkAction,
    onCreateCancelAction,
  ] = useModal();

  const [
    isEditModalShown,
    onSetIsEditModalShown,
    onEditOkAction,
    onEditCancelAction,
  ] = useModal();

  const [
    isDeleteModalShown,
    onSetIsDeleteModalShown,
    onDeleteOkAction,
    onDeleteCancelAction,
  ] = useModal();

  const discountForm = useRef({});

  useEffect(() => {
    const onMount = async () => {
      await fetchAll();
    };

    onMount();

    return resetSearch;
  }, [resetSearch, fetchAll]);

  const formatDiscountValues = values => {
    const endOfExpirationDate = values.expirationDate
      .utc()
      .add(1, 'day')
      .startOf('day')
      .format();
    const isActive = !!values.isActive;

    return {
      ...values,
      expirationDate: endOfExpirationDate,
      isActive,
    };
  };

  const onCreate = () =>
    discountForm.current.validateFields(async (error, values) => {
      if (error) return;

      const body = formatDiscountValues(values);
      await createDiscount(body);
      onCreateCancelAction()();
    });

  const onEdit = () =>
    discountForm.current.validateFields(async (error, values) => {
      if (error) return;

      const body = formatDiscountValues(values);
      await updateDiscount(discount.id, body);
    });

  const renderActions = row => {
    const { id } = row;

    return (
      <>
        <div>
          <Button
            type={tableActions.updateDiscountButton.type}
            icon={tableActions.updateDiscountButton.icon}
            onClick={onSetIsEditModalShown(findOne(id))}
          >
            {tableActions.updateDiscountButton.title}
          </Button>
        </div>
        <div>
          <Button
            type={tableActions.deleteButton.type}
            icon={tableActions.deleteButton.icon}
            color="#ff7875"
            onClick={onSetIsDeleteModalShown(findOne(id))}
          >
            {tableActions.deleteButton.title}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        title={modals.create.title}
        visible={isCreateModalShown}
        width="40%"
        destroyOnClose
        onOk={onCreateOkAction(onCreate)}
        onCancel={onCreateCancelAction()}
      >
        <DiscountForm ref={discountForm} />
      </Modal>
      <Modal
        title={modals.edit.title}
        visible={isEditModalShown}
        width="40%"
        destroyOnClose
        onOk={onEditOkAction(onEdit)}
        onCancel={onEditCancelAction()}
      >
        <DiscountForm ref={discountForm} discount={discount} />
      </Modal>
      <Modal
        title={modals.delete.title}
        visible={isDeleteModalShown}
        width="30%"
        destroyOnClose
        onOk={onDeleteOkAction(deleteDiscount(discount.id))}
        onCancel={onDeleteCancelAction()}
      >
        {modals.delete.inner(discount.code)}
      </Modal>
      <Row type="flex" style={{ marginBottom: '15px' }}>
        <Col span={2}>
          <Button
            type={createButton.type}
            icon={createButton.icon}
            onClick={onSetIsCreateModalShown()}
          >
            {createButton.title}
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        data={discounts}
        renderActions={renderActions}
        pagination
        paginationTotal={total}
        onChangePagination={changePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    discountsStore: rootStore.discountsStore,
  })),
  observer
)(Discounts);
