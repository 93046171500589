import React from 'react';
import { Layout as AntdLayout } from 'antd';

import Sidebar from '../sidebar';
import Header from '../header';
import Content from '../content';

const Layout = ({ children }) => (
  <AntdLayout>
    <Sidebar />
    <AntdLayout>
      <Header />
      <Content>{children}</Content>
    </AntdLayout>
  </AntdLayout>
);

export default Layout;
