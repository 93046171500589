import React from 'react';

import { normalizeMoneyValue } from '../../services/helpers';

export const columns = [
  {
    title: 'Destination',
    dataIndex: 'country',
    width: 200,
  },
  {
    title: <div>Still wine, total (&euro;)</div>,
    dataIndex: 'totalStillPrice',
    align: 'center',
  },
  {
    title: 'Total bottles',
    dataIndex: 'totalStillBottles',
    align: 'center',
  },
  {
    title: <div>Sparkling wine, total (&euro;) </div>,
    dataIndex: 'totalSparklingPrice',
    align: 'center',
  },
  {
    title: 'Total bottles',
    dataIndex: 'totalSparklingBottles',
    align: 'center',
  },
  {
    title: 'Total delivery',
    dataIndex: 'delivery',
    render: (_, { delivery }) => normalizeMoneyValue(delivery),
    align: 'center',
  },
  {
    title: <div>Amount (&euro;)</div>,
    dataIndex: 'allTotal',
    align: 'center',
  },
];

export const selectOptions = [
  { name: 'Destination country', value: 'buyer' },
  { name: 'Origin country', value: 'estate' },
];

export const optionsCSV = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  filename: 'reports',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
