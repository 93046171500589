import styled from 'styled-components';

export const StyledAlertStatusCell = styled.div`
  width: 150px;
  height: 96px;
  margin: -16px;
  padding-top: 37px;
  background-color: ${({ alert }) => alert ? 'red' : null};
  text-align: center;
`;
