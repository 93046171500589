import React from 'react';

import { StyledPromoStatusCell } from './styled';

const PromoStatusCell = ({ hasActivePromo }) => (
  <StyledPromoStatusCell status={hasActivePromo ? 'active' : 'inactive'}>
    {hasActivePromo ? 'ACTIVE' : 'INACTIVE'}
  </StyledPromoStatusCell>
);

export default PromoStatusCell;
