import { get } from 'lodash';
import React from 'react';
import { List, Switch, Icon } from 'antd';

import { api, urls } from '../../../services';
import { regexValidate } from '../../../services/helpers';

let delayTimer;

const getInitialValue = (path, defaultValue = '') => (data = {}) =>
  get(data, path, defaultValue);

export const platforms = {
  marketplace: 'marketplace',
  salesPoint: 'sales-point',
  webConnect: 'web-connect',
};

export const sellerForm = {
  layout: 'vertical',
  firstNameField: {
    label: 'First name',
    name: 'firstName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    defaultValue: '',
  },
  lastNameField: {
    label: 'Last name',
    name: 'lastName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    defaultValue: '',
  },
  estateNameField: {
    label: 'Estate name',
    name: 'estateName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    defaultValue: '',
  },
  emailField: {
    label: 'Email',
    name: 'email',
    rules: [
      {
        required: true,
        asyncValidator(_, value) {
          return new Promise((resolve, reject) => {
            const { method, url } = urls.users.validateEmailForUniqueness;

            if (!regexValidate('email', value)) {
              return reject('Please fill in valid email');
            }

            clearTimeout(delayTimer);
            delayTimer = setTimeout(async function() {
              const [response] = await api.makeRequest({
                method,
                url: url(encodeURIComponent(value)),
              });

              if (response.data.unique) {
                resolve();
              } else {
                reject('User with such email alredy exists');
              }
            }, 500);
          });
        },
      },
    ],
    type: 'email',
    defaultValue: '',
  },
  subscriptions: {
    marketplace: { label: 'Marketplace Subscription', name: 'subscription' },
    sp: { label: 'SP Subscription', name: 'sp_subscription' },
    wc: { label: 'WC Subscription', name: 'wc_subscription' },
  },
};

export const sellerEmailUpdateForm = {
  layout: 'vertical',
  emailField: {
    label: 'Email',
    name: 'email',
    rules: [
      { required: true, type: 'email', message: 'Please fill in valid email' },
    ],
    type: 'email',
    defaultValue: '',
  },
};

export const sellerProfileForm = {
  layout: 'vertical',
  firstNameField: {
    label: 'First name',
    name: 'firstName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    initialValue: getInitialValue('firstName'),
  },
  lastNameField: {
    label: 'Last name',
    name: 'lastName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    initialValue: getInitialValue('lastName'),
  },
  estateNameField: {
    label: 'Estate site name',
    name: 'estateName',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field. Length must 2 or more characters.',
      },
    ],
    initialValue: getInitialValue('estateName'),
  },
  vatField: {
    label: 'VAT No, Business ID',
    name: 'vat',
    rules: [
      {
        required: true,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('vat'),
  },
  notificationEmailField: {
    label: 'Notification email',
    name: 'notificationEmail',
    rules: [{ required: true, message: 'Required field' }],
    type: 'email',
    initialValue: getInitialValue('notificationEmail'),
  },
  contactNumberField: {
    label: 'Contact number',
    name: 'phoneNumber',
    rules: [{ required: true, message: 'Required field' }],
    type: 'text',
    initialValue: getInitialValue('phoneNumber'),
  },
  countryField: {
    name: 'countryId',
    label: 'Select your country',
    rules: [
      {
        type: 'number',
        required: true,
        message: 'Required field',
      },
    ],
  },
  regionField: {
    name: 'regionId',
    label: 'Select your region',
    rules: [
      {
        type: 'number',
        required: true,
        message: 'Required field',
      },
    ],
  },
  countriesToSellField: {
    name: 'countryIds',
    label: 'Countries to sell',
    rules: {
      type: 'array',
      message: 'Required field.',
    },
    initialValue: (countries, data, fieldName) => {
      if (!data[fieldName]) return [];

      const normalizedIndexes = data[fieldName].map(id => parseInt(id));

      return countries.reduce((acc, c) => {
        if (normalizedIndexes.includes(c.id)) {
          return [...acc, { key: c.id }];
        }

        return acc;
      }, []);
    },
  },
  descriptionField: {
    label: 'Detailed description',
    name: 'description',
    rules: {
      message: 'This is required field',
    },
    initialValue: getInitialValue('description'),
  },
  dobField: {
    label: 'Date of birth',
    name: 'dob',
    rules: [{ required: true, message: 'Required field' }],
    placeholder: 'DD/MM/YYYY',
    defaultValue: '',
  },
  addresseeCountryField: {
    label: 'Country',
    name: 'addresseeCountryId',
    defaultValue: '',
  },
  addresseeStateField: {
    label: 'State',
    name: 'addresseeState',
    initialValue: getInitialValue('addresseeState'),
  },
  registeredEstateNameField: {
    label: 'Registered estate name',
    name: 'registeredEstateName',
    initialValue: getInitialValue('registeredEstateName'),
  },
  invoiceAddressField: {
    label: 'Address',
    name: 'address',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field.',
      },
    ],
    initialValue: getInitialValue('address'),
  },
  invoiceAdditionalAddressField: {
    label: 'Additional address',
    name: 'billingAdditionalAddress',
    initialValue: getInitialValue('billingAdditionalAddress'),
  },
  invoiceCityField: {
    label: 'City',
    name: 'addresseeCity',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'This is required field.',
      },
    ],
    initialValue: getInitialValue('addresseeCity'),
  },
  invoicePostalCodeField: {
    label: 'Postal code',
    name: 'addresseePostalCode',
    rules: [
      {
        required: true,
        message: 'This is required field.',
      },
    ],
    initialValue: getInitialValue('addresseePostalCode'),
  },
  pickUpAddressField: {
    label: 'Address',
    name: 'pickupAddress',
    initialValue: getInitialValue('pickupAddress'),
  },
  pickUpCityField: {
    label: 'City',
    name: 'pickupCity',
    initialValue: getInitialValue('pickupCity'),
  },
  pickUpPostalCodeField: {
    label: 'Postal code',
    name: 'pickupPostalCode',
    initialValue: getInitialValue('pickupPostalCode'),
  },
  billingAddressField: {
    label: 'Address',
    name: 'billingAddress',
    initialValue: getInitialValue('billingAddress'),
  },
  billingAdditionalAddressField: {
    label: 'Additional address',
    name: 'billingAdditionalAddress',
    initialValue: getInitialValue('billingAdditionalAddress'),
  },
  billingCityField: {
    label: 'City',
    name: 'billingCity',
    initialValue: getInitialValue('billingCity'),
  },
  billingPostalCodeField: {
    label: 'Postal code',
    name: 'billingPostalCode',
    initialValue: getInitialValue('billingPostalCode'),
  },
  subscriptions: {
    marketplace: {
      label: 'Marketplace Subscription',
      name: 'subscription',
      initialValue: getInitialValue('subscription'),
    },
    sp: {
      label: 'SP Subscription',
      name: 'sp_subscription',
      initialValue: getInitialValue('sp_subscription'),
    },
    wc: {
      label: 'WC Subscription',
      name: 'wc_subscription',
      initialValue: getInitialValue('wc_subscription'),
    },
  },
  platformSelectOptions: [
    { label: 'Marketplace', value: platforms.marketplace },
    { label: 'Sales Point', value: platforms.salesPoint },
    { label: 'Web Connect', value: platforms.webConnect },
  ],
  initialFieldsRequired: {
    description: true,
    uploadMorePhotos: true,
    countriesToShip: true,
  },
};

export const sellerDiscountColumns = switchPromo => [
  {
    title: 'Code',
    dataIndex: 'discount.code',
    align: 'center',
  },
  {
    title: 'Provider',
    dataIndex: 'discount.owner',
    align: 'center',
  },
  {
    title: 'Promo Type',
    dataIndex: 'discount.promoType',
    align: 'center',
    render: (_, { discount: { promoType } }) => (
      <List split={false}>
        {promoType.map((type, index) => (
          <List.Item key={index}>{type}</List.Item>
        ))}
      </List>
    ),
  },
  {
    title: 'WJ amount',
    dataIndex: 'discount.marketplaceAmount',
    align: 'center',
  },
  {
    title: 'Seller amount',
    dataIndex: 'discount.sellerAmount',
    align: 'center',
  },
  {
    title: 'Expiration date',
    dataIndex: 'discount.expirationDate',
    align: 'center',
  },
  {
    title: 'Using',
    dataIndex: 'isActive',
    align: 'center',
    render: (_, promo) => (
      <Switch
        checkedChildren={<Icon type="check" />}
        unCheckedChildren={<Icon type="close" />}
        defaultChecked={promo.isActive}
        onChange={switchPromo(promo.id)}
      ></Switch>
    ),
  },
];
