import React from 'react';

import { StyledTrackingNumberCell } from './styled';

const TrackingNumberCell = ({ trackingNumbers }) => (
  <StyledTrackingNumberCell>
    {trackingNumbers
      ? trackingNumbers.split(',').map(tn => <span key={tn}>{tn}</span>)
      : ''}
  </StyledTrackingNumberCell>
);

export default TrackingNumberCell;
