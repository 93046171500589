import React from 'react';
import { Tag, Rate, Icon } from 'antd';

import { TableComponents } from '../../components/table';
import { normalizeMoneyValue } from '../../services/helpers';

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="Name">
        <TableComponents.Search dataIndex="name" storeName="productsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'name',
    width: 200,
  },
  {
    title: () => (
      <TableComponents.Title title="Estate">
        <TableComponents.Search
          dataIndex="user.profile.estateName"
          storeName="productsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'user.profile.estateName',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Wine Rating">
        <TableComponents.Search dataIndex="rating" storeName="productsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'rating',
    render: (_, { rating, ratingStatus }) => {
      return rating && showRating(ratingStatus) ? (
        <Rate
          disabled
          allowHalf
          value={rating}
          character={
            <Icon
              type="star"
              theme="filled"
              style={{ fontSize: '14px', paddingBottom: '5px' }}
            />
          }
          style={{ color: '#b81928' }}
        />
      ) : (
        ''
      );
    },
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Rating Status">
        <TableComponents.Search
          dataIndex="ratingStatus"
          storeName="productsStore"
          type="select"
          selectOptions={ratingStatuses}
        />
      </TableComponents.Title>
    ),
    dataIndex: 'ratingStatus',
    render: (_, { ratingStatus }) => {
      return <TableComponents.RatingStatusCell ratingStatus={ratingStatus} />;
    },
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Price">
        <TableComponents.Search dataIndex="price" storeName="productsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'price',
    render: (_, { price }) => normalizeMoneyValue(price),
    align: 'center',
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    align: 'center',
  },
  {
    title: 'Alcohol',
    dataIndex: 'alcohol',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Vintage">
        <TableComponents.Search dataIndex="vintage" storeName="productsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'vintage',
    align: 'center',
  },
  {
    title: 'Purchasable',
    dataIndex: 'purchasable',
    render: (_, { purchasable }) => (purchasable ? 'Yes' : 'No'),
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Wine type">
        <TableComponents.Search
          dataIndex="wineType.name"
          storeName="productsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'wineType.name',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Country">
        <TableComponents.Search
          dataIndex="country.name"
          storeName="productsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'country.name',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Region">
        <TableComponents.Search
          dataIndex="region.name"
          storeName="productsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'region.name',
    align: 'center',
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    render: (_, { categories }) =>
      categories.map(({ name }) => (
        <Tag key={name} color="#108ee9" style={{ marginBottom: '7px' }}>
          {name}
        </Tag>
      )),
    width: 150,
  },
];

export const modals = {
  create: {
    title: 'Create product',
  },
  update: {
    title: 'Update product',
  },
  delete: {
    title: 'Delete product',
    inner: product => (
      <p>
        Do you really want to delete <b>{product}</b> product?
      </p>
    ),
  },
};

export const tableActions = {
  edit: {
    title: 'Edit',
    type: 'link',
    icon: 'edit',
  },
  delete: {
    title: 'Delete',
    type: 'link',
    icon: 'delete',
  },
};

export const maxStarsCount = 5;

export const ratingStatuses = [
  { value: 'new', title: 'No Rating' },
  { value: 'onReview', title: 'Waiting For Approval' },
  { value: 'approved', title: 'Approved' },
  { value: 'canceled', title: 'Rejected' },
];

export const showRating = ratingStatus => {
  const showStarsRatings = ['approved', 'onReview'];
  return showStarsRatings.includes(ratingStatus);
};
