export const loginForm = {
  layout: 'vertical',
  iconStyle: { color: 'rgba(0,0,0,.25)' },
  submitButton: {
    type: 'primary',
    htmlType: 'submit',
    title: 'Log in',
    size: 'large',
    style: { width: '100%' },
  },
  emailField: {
    name: 'email',
    rules: [{ required: true, message: 'This is required field' }],
    prefix: {
      type: 'mail',
    },
    type: 'text',
    placeholder: 'email',
  },
  passwordField: {
    name: 'password',
    rules: [{ required: true, message: 'This is required field' }],
    prefix: {
      type: 'lock',
    },
    type: 'password',
    placeholder: 'password',
  },
};
