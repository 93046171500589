import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';

import routes, { login as loginRouteProps } from './routes';
import { ProtectedRoute, LoginRoute } from './components/routes';
import RootStore from './stores/Root';

const routingStore = new RouterStore();
export const rootStore = new RootStore(routingStore);
const history = syncHistoryWithStore(createBrowserHistory(), routingStore);
const storesWithRouting = { rootStore };

const App = () => (
  <Provider {...storesWithRouting}>
    <Router history={history}>
      <Switch>
        <LoginRoute {...loginRouteProps} />
        {routes.map(props => (
          <ProtectedRoute key={props.path} {...props} />
        ))}
        <Route path="*">
          <Redirect to="/estates" />
        </Route>
      </Switch>
    </Router>
  </Provider>
);

export default App;
