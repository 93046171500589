import { observable, action } from 'mobx';
import { message } from 'antd';

import { api, urls } from '../services';
import Estates from './Estates';
import Countries from './Countries';
import Buyers from './Buyers';
import Products from './Products';
import Orders from './Orders';
import Auth from './Auth';
import WineGroups from './WineGroups';
import Reports from './Reports';
import Sitemap from './Sitemap';
import Discounts from './Discounts';
import Alerts from './Alerts';

class Root {
  message = message;
  urls = urls;
  @observable pending = false;
  @observable hasError = false;

  constructor(routingStore) {
    this.routingStore = routingStore;
    this.estatesStore = new Estates(this);
    this.countriesStore = new Countries(this);
    this.buyersStore = new Buyers(this);
    this.productsStore = new Products(this);
    this.ordersStore = new Orders(this);
    this.authStore = new Auth(this);
    this.wineGroupsStore = new WineGroups(this);
    this.reportsStore = new Reports(this);
    this.sitemapStore = new Sitemap(this);
    this.discountsStore = new Discounts(this);
    this.alertsStore = new Alerts(this);
  }

  abortRequest() {
    api.abort();
  }

  @action resetErrors() {
    this.hasError = false;
  }

  @action handleError(error) {
    // todo: add better error messaging
    const status = error.status;
    const unauthorizedStatus = status === 401;
    const validationError = status === 400;

    this.hasError = true;

    if (validationError) {
      return this.message.error('Validation error');
    }

    if (unauthorizedStatus) {
      return this.message.error('Unauthorized');
    }

    this.message.error('An error occur, please try again');
  }

  @action async makeRequest(cb, method, url, body, headers, responseType) {
    this.pending = true;
    this.resetErrors();

    const [response, error] = await api.makeRequest({
      method,
      url,
      data: method === 'get' || method === 'del' ? {} : body,
      headers,
      responseType,
    });
    setTimeout(() => (this.pending = false), 300);

    if (error) {
      return this.handleError(error);
    }

    if (response && responseType) {
      return cb(response);
    }

    if (response) {
      const { data } = response;
      return await cb(data, body);
    }
  }
}

export default Root;
