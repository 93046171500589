import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Select, Row, Col, Divider, Switch, Icon } from 'antd';
import { observer } from 'mobx-react';

import { productForm, WINE_VOLUME_ENUMS, VINTAGE_ENUMS } from './settings';
import { compose } from '../../../services/helpers';
import ImagesUploadForm from './images-upload-form';
import ProductRatingForm from './product-rating-form';

const { Option } = Select;
const { TextArea } = Input;
const {
  layout,
  nameField,
  vintageField,
  wineTypeField,
  grapeSortField,
  wineStylesField,
  organicAndMoreField,
  fishAndMeatField,
  cookingStylesField,
  priceField,
  descriptionField,
  volumeField,
  alcoholField,
  userField,
  purchasableField,
} = productForm;

const ProductForm = ({
  form,
  groups: { types, styles, organics, forFood, forCooking },
  product,
  estates,
  ratingSystem,
}) => {
  const { categories: productCategories } = product;
  const [wineTypeId, setWineTypeId] = useState(
    product.wineType ? product.wineType.id : null
  );
  const [grapeSorts, setGrapeSorts] = useState([]);

  const onWineTypeChange = useCallback(
    id => {
      const wineType = types.find(t => t.id === id);
      if (wineType) {
        setGrapeSorts(wineType.categories || []);
      }
      setWineTypeId(id);
    },
    [types]
  );

  useEffect(() => onWineTypeChange(wineTypeId), [
    types.length,
    onWineTypeChange,
    wineTypeId,
  ]);

  return (
    <Form layout={layout}>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={userField.label}>
            {form.getFieldDecorator(userField.name, {
              rules: userField.rules,
              initialValue: userField.initialValue(product),
            })(
              <Select disabled={!!product.id}>
                {estates.map(({ id, profile: { fullName } }) => (
                  <Option key={id} value={id}>
                    {fullName}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={purchasableField.label}>
            {form.getFieldDecorator(purchasableField.name)(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked={product.purchasable}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={nameField.label}>
            {form.getFieldDecorator(nameField.name, {
              rules: nameField.rules,
              initialValue: nameField.initialValue(product),
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={vintageField.label}>
            {form.getFieldDecorator(vintageField.name, {
              rules: vintageField.rules,
              initialValue: vintageField.initialValue(product),
            })(
              <Select>
                {VINTAGE_ENUMS.map(({ name, id }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={wineTypeField.label}>
            {form.getFieldDecorator(wineTypeField.name, {
              rules: wineTypeField.rules,
              initialValue: wineTypeId,
            })(
              <Select onChange={onWineTypeChange}>
                {types.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={grapeSortField.label}>
            {form.getFieldDecorator(grapeSortField.name, {
              rules: grapeSortField.rules,
              initialValue: grapeSortField.initialValue(
                grapeSorts,
                productCategories
              ),
            })(
              <Select labelInValue mode="multiple">
                {grapeSorts.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={wineStylesField.label}>
            {form.getFieldDecorator(wineStylesField.name, {
              rules: wineStylesField.rules,
              initialValue: wineStylesField.initialValue(
                styles,
                productCategories
              ),
            })(
              <Select>
                {styles.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={organicAndMoreField.label}>
            {form.getFieldDecorator(organicAndMoreField.name, {
              rules: organicAndMoreField.rules,
              initialValue: organicAndMoreField.initialValue(
                organics,
                productCategories
              ),
            })(
              <Select labelInValue mode="multiple">
                {organics.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={fishAndMeatField.label}>
            {form.getFieldDecorator(fishAndMeatField.name, {
              rules: fishAndMeatField.rules,
              initialValue: fishAndMeatField.initialValue(
                forFood,
                productCategories
              ),
            })(
              <Select labelInValue mode="multiple">
                {forFood.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={cookingStylesField.label}>
            {form.getFieldDecorator(cookingStylesField.name, {
              rules: cookingStylesField.rules,
              initialValue: cookingStylesField.initialValue(
                forCooking,
                productCategories
              ),
            })(
              <Select labelInValue mode="multiple">
                {forCooking.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={priceField.label}>
            {form.getFieldDecorator(priceField.name, {
              rules: priceField.rules,
              initialValue: `${priceField.initialValue(product)}`,
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label={descriptionField.label}>
            {form.getFieldDecorator(descriptionField.name, {
              rules: descriptionField.rules,
              initialValue: descriptionField.initialValue(product),
            })(<TextArea rows={6} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" style={{ marginBottom: '50px' }}>
        <ImagesUploadForm product={product} />
      </Row>
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item label={volumeField.label}>
            {form.getFieldDecorator(volumeField.name, {
              rules: volumeField.rules,
              initialValue: volumeField.initialValue(product),
            })(
              <Select>
                {WINE_VOLUME_ENUMS.map(volume => (
                  <Option key={volume} value={volume}>
                    {volume}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={alcoholField.label}>
            {form.getFieldDecorator(alcoholField.name, {
              rules: alcoholField.rules,
              initialValue: alcoholField.initialValue(product),
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <ProductRatingForm
        product={product}
        form={form}
        ratingSystem={ratingSystem}
      />
    </Form>
  );
};

export default compose(
  Form.create(),
  observer
)(ProductForm);
