import * as localForage from 'localforage';

class LocalStorage {
  async save(key, value) {
    return await localForage.setItem(key, value);
  }

  async get(key) {
    return await localForage.getItem(key);
  }

  async remove(key) {
    return await localForage.removeItem(key);
  }
}

export default new LocalStorage();
