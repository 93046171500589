import Search from './search';
import Title from './title';
import ClickableCell from './clickable-cell';
import TrackingNumberCell from './tracking-number-cell';
import UpsStatusCell from './ups-status-cell';
import PromoStatusCell from './promo-status-cell';
import PaymentStatusCell from './payment-status-cell';
import RatingStatusCell from './rating-status-cell';
import AlertStatusCell from './alert-status-cell';
import SubscriptionsCell from './subscriptions-cell';

export { default as Table } from './table';
export const TableComponents = {
  Search,
  Title,
  ClickableCell,
  TrackingNumberCell,
  UpsStatusCell,
  PromoStatusCell,
  PaymentStatusCell,
  RatingStatusCell,
  AlertStatusCell,
  SubscriptionsCell,
};
