import styled from 'styled-components';

const colorMapPaymentStatuses = {
  paid: 'green',
  failed: 'red',
  'customerbehalf': '#eb7734' ,
  'customerbehalfconfirmed': 'green' ,
  'customerbehalfpaid': '#998306' ,
};

export const StyledPaymentStatusCell = styled.div`
  width: 150px;
  height: 96px;
  margin: -16px;
  padding-top: 37px;
  color: ${({ status }) => colorMapPaymentStatuses[status]};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;
