import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import { Table } from '../../components/table';
import { columns, modals, tableActions } from './settings';
import { compose } from '../../services/helpers';
import { ProductForm } from '../../components/forms';
import { useModal } from '../../common';
import Modal from '../../components/modal';
import Button from '../../components/button';

const Products = ({ productsStore, wineGroupsStore, estatesStore }) => {
  const {
    products,
    fetchAll,
    findOne,
    create,
    update,
    resetSearch,
    product,
    ratingSystem,
    delete: deleteProduct,
    total,
    changePagination,
  } = productsStore;
  const { fetchAll: fetchWineGroups, ...restWineGroupsData } = wineGroupsStore;
  const { estates } = estatesStore;

  const [
    isCreateModalShown,
    onSetIsCreateModalShown,
    onCreateOkAction,
    onCreateCancelAction,
  ] = useModal();
  const [
    isEditModalShown,
    onSetIsEditModalShown,
    onEditOkAction,
    onEditCancelAction,
  ] = useModal();
  const [
    isDeleteModalShown,
    onSetIsDeleteModalShown,
    onDeleteOkAction,
    onDeleteCancelAction,
  ] = useModal();

  const productEditForm = useRef({});

  useEffect(() => {
    const onMount = async () => {
      await fetchAll();
      await fetchWineGroups();
      await estatesStore.fetchAll();
    };

    onMount();

    return resetSearch;
  }, [resetSearch, fetchAll, fetchWineGroups, estatesStore]);

  const onCreate = () =>
    productEditForm.current.validateFields(async (err, values) => {
      if (err) return;

      await create(values);
      onCreateCancelAction()();
    });

  const onUpdate = () =>
    productEditForm.current.validateFields(async (err, values) => {
      if (err) return;

      await update(values);
      onEditCancelAction()();
    });

  const renderActions = row => (
    <>
      <div>
        <Button
          type={tableActions.edit.type}
          icon={tableActions.edit.icon}
          onClick={onSetIsEditModalShown(findOne(row.id))}
        >
          {tableActions.edit.title}
        </Button>
      </div>
      <div>
        <Button
          type={tableActions.delete.type}
          icon={tableActions.delete.icon}
          color="#ff7875"
          onClick={onSetIsDeleteModalShown(findOne(row.id))}
        >
          {tableActions.delete.title}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Modal
        title={modals.create.title}
        visible={isCreateModalShown}
        width="70%"
        destroyOnClose
        onOk={onCreateOkAction(onCreate)}
        onCancel={onCreateCancelAction(productsStore.resetSelectedProduct)}
      >
        <ProductForm
          ref={productEditForm}
          groups={restWineGroupsData}
          product={product}
          estates={estates}
          ratingSystem={ratingSystem}
        />
      </Modal>
      <Modal
        title={modals.update.title}
        visible={isEditModalShown}
        width="70%"
        destroyOnClose
        onOk={onEditOkAction(onUpdate)}
        onCancel={onEditCancelAction(productsStore.resetSelectedProduct)}
      >
        <ProductForm
          ref={productEditForm}
          groups={restWineGroupsData}
          product={product}
          estates={estates}
          ratingSystem={ratingSystem}
        />
      </Modal>
      <Modal
        title={modals.delete.title}
        visible={isDeleteModalShown}
        width="30%"
        destroyOnClose
        onOk={onDeleteOkAction(deleteProduct(product.id))}
        onCancel={onDeleteCancelAction()}
      >
        {modals.delete.inner(product ? product.name : '')}
      </Modal>
      <Table
        columns={columns}
        data={products}
        renderActions={renderActions}
        onCreate={products.length ? onSetIsCreateModalShown() : null}
        pagination
        paginationTotal={total}
        onChangePagination={changePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    productsStore: rootStore.productsStore,
    wineGroupsStore: rootStore.wineGroupsStore,
    estatesStore: rootStore.estatesStore,
  })),
  observer
)(Products);
