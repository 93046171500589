import { get, includes, isNumber, round, set } from "lodash";
import { v4 as uuid } from "uuid";

export const compose = (...fns) =>
  fns.reduce((a, b) => (...args) => a(b(...args)), (arg) => arg);

export const normalizeMoneyValue = (data) => {
  const priceParts = `${round(data, 2)}`.split(".");
  const normalizeCents = (cents) => {
    if (!cents) return "00";
    if (cents && cents.length === 1) return `${cents}0`;
    return cents;
  };

  const mainPart = priceParts[0];
  const cents = normalizeCents(priceParts[1]);

  return `${mainPart}.${cents}`;
};

export const searchInEntityList = (list, searchIn, searchValue) => {
  return list.filter((e) => {
    const value = get(e, searchIn);

    if (isNumber(value)) {
      return value === +searchValue;
    }

    return includes(`${value}`.toLowerCase(), searchValue);
  });
};

export const getVintageYears = () => {
  const vintage = [{ name: "NON VINTAGE", id: "NON VINTAGE" }];
  let year = new Date().getFullYear();

  for (; year >= 1900; year--) {
    vintage.push({ name: `${year}`, id: `${year}` });
  }

  return vintage;
};

export const takeChecked = (allValues, checkedValues) =>
  allValues.reduce((acc, value) => {
    const hasValue = checkedValues.find((cv) => cv.id === value.id);

    if (hasValue) {
      return [...acc, value];
    }

    return acc;
  }, []);

export const normalizeProductData = (action, rawData) => {
  const {
    styles,
    grapeSorts,
    organics,
    forFood,
    forCooking,
    price,
    alcohol,
    user,
    ...rest
  } = rawData;

  const categories = [
    ...grapeSorts,
    ...organics,
    ...forFood,
    ...forCooking,
  ].map(({ key }) => key);

  switch (action) {
    case "create": {
      return {
        id: uuid(),
        userId: user,
        categories: [...categories, styles],
        price: +price,
        alcohol: +alcohol,
        ...rest,
      };
    }
    case "update": {
      return {
        categories: [...categories, styles],
        price: +price,
        alcohol: +alcohol,
        ...rest,
      };
    }
    default:
      return {};
  }
};

export const regexValidate = (key, value) => {
  const regexStore = {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };

  return new RegExp(regexStore[key]).test(value);
};

export const titleToUpperCase = (entity) => {
  if (entity.title.hasOwnProperty("props")) {
    return {
      ...entity,
      title: {
        ...entity.title,
        props: {
          ...entity.title.props,
          children: entity.title.props.children.toUpperCase(),
        },
      },
    };
  }

  return {
    ...entity,
    title: entity.title.toUpperCase(),
  };
};

export const fillMissingImages = ({ entity, path, correctAmount }) => {
  const images = get(entity, path, []);
  const hasImages = !!images.length;
  const imagesLength = hasImages ? images.length : 0;
  const shouldAddTail = !hasImages || imagesLength < correctAmount;

  if (shouldAddTail) {
    const tail = new Array(correctAmount - imagesLength).fill("");
    set(entity, path, [...images, ...tail]);
  }
};
