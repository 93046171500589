class Buyer {
  profile = {
    firstName: '',
    lastName: '',
  };

  constructor(estate, rootStore) {
    Object.assign(this, estate);
    this.rootStore = rootStore;
  }
}

export default Buyer;
