import { action, computed, observable } from 'mobx';

class Alerts {
  @observable rawAlerts = [];
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get alerts() {
    return this.rawAlerts;
  }

  @action.bound onFetchAll(data) {
    this.rawAlerts = data.raw;
    this.total = data.total;
  }

  @action.bound async fetchAll() {
    const {
      getAll: { method, url },
    } = this.rootStore.urls.alerts;

    return this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(15)
    );
  }

  @action.bound onChangePagination(data) {
    this.rawAlerts = data.raw;
    this.total = data.total;
  }

  @action.bound async changePagination(page, pageSize) {
    if (!this.searchValue || !this.searchIn) {
      const { method, url } = this.rootStore.urls.alerts.getPageData;
      return this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize)
      );
    }

    return this.searchAlerts(this.searchIn, this.searchValue, page);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';
    return this.fetchAll();
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLocaleLowerCase();
    this.searchIn = field;
    return this.searchAlerts(this.searchIn, this.searchValue);
  }

  async searchAlerts(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.alerts.search;
    return this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, 15, page)
    );
  }
}

export default Alerts;
