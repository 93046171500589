import React from 'react';
import { Pagination as AntdPagination } from 'antd';

const Pagination = ({ total, onChange }) => (
  <AntdPagination
    style={{ marginTop: '24px' }}
    total={total}
    pageSize={15}
    onChange={onChange}
  />
);

export default Pagination;
