import superagent from 'superagent';
import { isObject, isString } from 'lodash';
import of from 'await-of';

import ls from './local-storage';
import apiUrls from './api-urls';

class Api {
  request = null;
  allowedMethods = ['get', 'post', 'put', 'del'];
  host = process.env.REACT_APP_SERVER_HOST;

  validateUrl = url => {
    if (!isString(url)) {
      throw new Error(`{url} parameter must be a string, got a ${typeof url}`);
    }
  };

  validateHeaders = headers => {
    if (!isObject(headers)) {
      throw new Error(
        `{headers} parameter must be a valid object, got a ${typeof headers}`
      );
    }
  };

  validateMethod = method => {
    if (!this.allowedMethods.includes(method)) {
      throw new Error(
        `Method ${method} is not allowed. Please, use one of ${this.allowedMethods}.`
      );
    }
  };

  logout = async () => {
    await ls.remove('user');
    window.location.reload();
  };

  makeRequest = async ({
    method,
    url,
    data = {},
    headers = {},
    responseType = '',
  }) => {
    this.validateMethod(method);
    this.validateUrl(url);
    this.validateHeaders(headers);
    const user = await ls.get('user');
    const token = user && user.token;

    this.request = superagent[method](`${this.host}/${url}`, data).set({
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    });

    if (responseType) {
      this.request.responseType(responseType);
    }

    const [response, error] = await of(this.request);
    const body = response ? response.body : null;
    const shouldLogout =
      error && error.status === 401 && url !== apiUrls.login.url;

    if (shouldLogout) {
      this.logout();
    }

    return [body, error];
  };

  abort = () => {
    if (this.request) {
      return this.request.abort();
    }
    return null;
  };
}

export default new Api();
