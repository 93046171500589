import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { Table } from '../../components/table';
import Button from '../../components/button';
import Modal from '../../components/modal';
import {
  columns,
  tableActions,
  deliveryColumns,
  productColumns,
} from './settings';
import { compose } from '../../services/helpers';
import { useModal } from '../../common';

const Alerts = ({ alertsStore }) => {
  const {
    alerts,
    fetchAll,
    total,
    changePagination,
    resetSearch,
  } = alertsStore;

  const [
    isBuyerModalShown,
    onSetIsBuyerModalShown,
    onBuyerCancelAction,
  ] = useModal();

  const [
    isProductsModalShown,
    onSetIsProductsModalShown,
    onProductsCancelAction,
  ] = useModal();

  const [buyer, setBuyer] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const onMount = async () => {
      await fetchAll();
    };

    onMount();

    return resetSearch;
  }, [fetchAll, resetSearch]);

  const onShowBuyer = ({ deliveryAddress, buyer }) => () => {
    if (!deliveryAddress && buyer) {
      return setBuyer([{ ...buyer, pickupPointName: 'None' }]);
    }

    if (deliveryAddress) {
      try {
        const address = JSON.parse(deliveryAddress);
        return setBuyer([
          {
            ...buyer,
            ...address,
            pickupPointName: address.pickupPointName || address.name,
            id: address.name,
          },
        ]);
      } catch (e) {
        setBuyer(null);
      }
    }
  };

  const onShowAddressClose = () => setTimeout(() => setBuyer(null), 300);

  const onShowProducts = ({ products, comment, transactionId, buyer }) => () =>
    setProducts(
      (products || []).map(p => ({
        ...p,
        comment,
        transactionId,
        buyerState: buyer.state,
      }))
    );

  const onShowProductsClose = () => setTimeout(() => setProducts(null), 300);

  const onChangePagination = async (page, pageSize) => {
    await changePagination(page, pageSize);
  };

  const renderActions = row => {
    return (
      <>
        <div>
          <Button
            type={tableActions.buyerAndDeliveryInfo.type}
            icon={tableActions.buyerAndDeliveryInfo.icon}
            onClick={onSetIsBuyerModalShown(onShowBuyer(row))}
          >
            {tableActions.buyerAndDeliveryInfo.title}
          </Button>
        </div>
        <div>
          <Button
            type={tableActions.orderedProducts.type}
            icon={tableActions.orderedProducts.icon}
            onClick={onSetIsProductsModalShown(onShowProducts(row))}
          >
            {tableActions.orderedProducts.title}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        title={tableActions.buyerAndDeliveryInfo.title}
        visible={isBuyerModalShown}
        width="70%"
        destroyOnClose
        onOk={onBuyerCancelAction(onShowAddressClose)}
        onCancel={onBuyerCancelAction(onShowAddressClose)}
      >
        <Table columns={deliveryColumns} data={buyer} />
      </Modal>
      <Modal
        title={tableActions.orderedProducts.title}
        visible={isProductsModalShown}
        width="70%"
        destroyOnClose
        onOk={onProductsCancelAction(onShowProductsClose)}
        onCancel={onProductsCancelAction(onShowProductsClose)}
      >
        <Table columns={productColumns} data={products} />
      </Modal>
      <Table
        columns={columns}
        data={alerts}
        renderActions={renderActions}
        pagination
        paginationTotal={total}
        onChangePagination={onChangePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    alertsStore: rootStore.alertsStore,
  })),
  observer
)(Alerts);
