import React from 'react';

import { normalizeMoneyValue } from '../../services/helpers';
import { TableComponents } from '../../components/table';

const buyerColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Postal code',
    dataIndex: 'postalCode',
  },
];

const sellerColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    width: 200,
  },
  {
    title: 'Estate name',
    dataIndex: 'estateName',
    align: 'center',
  },
  {
    title: 'Notification email',
    dataIndex: 'notificationEmail',
    align: 'center',
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    align: 'center',
  },
  {
    title: 'Vat',
    dataIndex: 'vat',
    align: 'center',
  },
  {
    title: 'Stripe account id',
    dataIndex: 'stripeAccountId',
    align: 'center',
  },
];

export const deliveryColumns = [
  {
    title: 'Pickup point',
    dataIndex: 'pickupPointName',
  },
  {
    title: "Buyer's name",
    dataIndex: 'fullName',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Postal code',
    dataIndex: 'postalCode',
  },
];

export const productColumns = [
  {
    title: 'Transaction id',
    dataIndex: 'transactionId',
    width: 150,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Estate price',
    dataIndex: 'estatePrice',
    render: (_, { estatePrice }) => normalizeMoneyValue(estatePrice),
    align: 'center',
  },
  {
    title: 'Sparkling',
    dataIndex: 'sparkling',
    align: 'center',
    render: (_, { sparkling }) => (sparkling ? 'Yes' : 'No'),
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    align: 'center',
  },
  {
    title: 'Alcohol',
    dataIndex: 'alcohol',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'center',
  },
  {
    title: "Buyer's state",
    dataIndex: 'buyerState',
  },
  {
    title: "Buyer's comment",
    dataIndex: 'comment',
  },
];

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="ORD ID">
        <TableComponents.Search dataIndex="orderId" storeName="alertsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'orderId',
    align: 'center',
    width: 150,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="ORGN">
        <TableComponents.Search
          dataIndex="sellerCountry.name"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'sellerCountry.iso2Code',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Estate">
        <TableComponents.Search
          dataIndex="seller.estateName"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'seller.estateName',
    align: 'center',
    render: (_, data) => (
      <TableComponents.ClickableCell
        data={data}
        dataIndex="seller.estateName"
        dataKey="seller"
        modalTitle="Seller info"
        columns={sellerColumns}
      />
    ),
  },
  {
    title: 'ORD STATUS',
    dataIndex: 'orderStatus',
    width: 150,
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Alert">
        <TableComponents.Search
          dataIndex="alert"
          storeName="alertsStore"
          type="select"
          selectOptions={selectAlertStatuses}
        />
      </TableComponents.Title>
    ),
    dataIndex: 'alert',
    align: 'center',
  },
  {
    title: 'PAYMNT ST',
    dataIndex: 'paymentStatus',
    width: 150,
    align: 'center',
    render: (_, { paymentStatus }) => (
      <TableComponents.PaymentStatusCell status={paymentStatus} />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="Buyer">
        <TableComponents.Search
          dataIndex="buyer.fullName"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'buyer.fullName',
    align: 'center',
    render: (_, data) => (
      <TableComponents.ClickableCell
        data={data}
        dataIndex="buyer.fullName"
        dataKey="buyer"
        modalTitle="Buyer info"
        columns={buyerColumns}
      />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="DEST">
        <TableComponents.Search
          dataIndex="buyer.country"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'buyer.iso2Code',
    align: 'center',
  },
  {
    title: 'UPS ST',
    dataIndex: 'upsStatus',
    width: 150,
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="TRCKN ID">
        <TableComponents.Search
          dataIndex="trackingNumbers"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'trackingNumbers',
    align: 'center',
    render: (_, { trackingNumbers }) => (
      <TableComponents.TrackingNumberCell trackingNumbers={trackingNumbers} />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="PRN">
        <TableComponents.Search
          dataIndex="pickupRequestNumber"
          storeName="alertsStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'pickupRequestNumber',
    align: 'center',
    width: 250,
  },
  {
    title: 'TOTAL',
    dataIndex: 'totalPrice',
    render: (_, { totalPrice }) => normalizeMoneyValue(totalPrice),
    align: 'center',
  },
  {
    title: 'TOTALWTHOUTDIS',
    dataIndex: 'totalWithoutDiscount',
    render: (_, { priceWithoutDiscount }) =>
      normalizeMoneyValue(priceWithoutDiscount),
    align: 'center',
  },
  {
    title: 'COUPON DSCNT',
    dataIndex: 'discount',
    render: (_, { discount }) => normalizeMoneyValue(discount),
    align: 'center',
  },
  {
    title: 'FEE',
    dataIndex: 'fee',
    render: (_, { fee }) => normalizeMoneyValue(fee),
    align: 'center',
  },
  {
    title: 'PYOUT',
    dataIndex: 'payout',
    render: (_, { totalPrice, fee }) => normalizeMoneyValue(+totalPrice - +fee),
    align: 'center',
  },
  {
    title: '#TOTAL BTLS',
    dataIndex: 'itemsAmount',
    align: 'center',
  },
  {
    title: '#STILL',
    dataIndex: 'stillAmount',
    render: (_, { products }) =>
      products.reduce(
        (acc, { sparkling, quantity }) => (sparkling ? acc : acc + quantity),
        0
      ),
    align: 'center',
  },
  {
    title: '#SPRKL',
    dataIndex: 'sparklingAmount',
    render: (_, { products }) =>
      products.reduce(
        (acc, { sparkling, quantity }) => (sparkling ? acc + quantity : acc),
        0
      ),
    align: 'center',
  },
];

export const tableActions = {
  buyerAndDeliveryInfo: {
    title: 'Delivery info',
    type: 'link',
    icon: 'eye',
  },
  orderedProducts: {
    title: 'Order info',
    type: 'link',
    icon: 'eye',
  },
};

export const AlertStatuses = {
  NOT_PROCESSED: 'NOT PROCESSED',
  NOT_MOVING: 'NOT MOVING',
  NOT_DELIVERED: 'NOT DELIVERED',
  NOT_PICKED_UP: 'NOT PICKED UP',
  EXCEPTION: 'EXCEPTION',
};

export const selectAlertStatuses = [
  { value: AlertStatuses.NOT_PROCESSED, title: 'Not processed' },
  { value: AlertStatuses.NOT_MOVING, title: 'Not moving' },
  { value: AlertStatuses.NOT_DELIVERED, title: 'Not delivered' },
  { value: AlertStatuses.NOT_PICKED_UP, title: 'Not picked up' },
  { value: AlertStatuses.EXCEPTION, title: 'Exception' },
];
