import React from 'react';
import { Col, Form, Icon, Input, Row, Switch } from 'antd';

import { sellerForm } from './settings';

const {
  layout,
  firstNameField,
  lastNameField,
  estateNameField,
  emailField,
  subscriptions: { marketplace, sp, wc },
} = sellerForm;

const SellerBasicForm = ({ form, entity }) => (
  <Form layout={layout}>
    <Form.Item label={firstNameField.label}>
      {form.getFieldDecorator(firstNameField.name, {
        rules: firstNameField.rules,
        initialValue: entity
          ? entity[firstNameField.name]
          : firstNameField.defaultValue,
      })(<Input />)}
    </Form.Item>
    <Form.Item label={lastNameField.label}>
      {form.getFieldDecorator(lastNameField.name, {
        rules: lastNameField.rules,
        initialValue: entity
          ? entity[lastNameField.name]
          : lastNameField.defaultValue,
      })(<Input />)}
    </Form.Item>
    <Form.Item label={estateNameField.label}>
      {form.getFieldDecorator(estateNameField.name, {
        rules: estateNameField.rules,
        initialValue: entity
          ? entity[estateNameField.name]
          : estateNameField.defaultValue,
      })(<Input />)}
    </Form.Item>
    <Form.Item label={emailField.label}>
      {form.getFieldDecorator(emailField.name, {
        rules: emailField.rules,
        initialValue: entity
          ? entity[emailField.name]
          : emailField.defaultValue,
      })(<Input type={emailField.type} />)}
    </Form.Item>
    <Row type="flex" justify="space-between">
      <Col span={6}>
        <Form.Item label={marketplace.label} defaultValue={false}>
          {form.getFieldDecorator(marketplace.name, { initialValue: false })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              defaultChecked={false}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={sp.label}>
          {form.getFieldDecorator(sp.name, { initialValue: false })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              defaultChecked={false}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={wc.label} defaultValue={false}>
          {form.getFieldDecorator(wc.name, { initialValue: false })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              defaultChecked={false}
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  </Form>
);

export default Form.create()(SellerBasicForm);
