import React from 'react';
import { Col } from 'antd';
import { observer } from 'mobx-react';

import { Upload } from '../../inputs';
import { compose } from '../../../services/helpers';

const ImagesUploadForm = ({ product }) =>
  product.images.map((image, i) => (
    <Col key={i} span={4}>
      <Upload
        styles={{ width: '100%' }}
        title={`Upload image ${i + 1}`}
        index={i}
        resourceId={product.id}
        imageUrl={image}
        onUpload={product.uploadImage}
        onDelete={product.deleteImage}
      />
    </Col>
  ));

export default compose(observer)(ImagesUploadForm);
