import styled from 'styled-components';
import { Upload } from 'antd';

export const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select {
    margin: 0;
    width: ${({ styles }) => styles.width};

    &:hover .image-upload-delete-btn {
      display: block;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const StyledDeleteButton = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  display: none;
  font-size: 14px;
  font-weight: bold;
  color: #ff0000;
`;
