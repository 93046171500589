import React, { useState } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Tabs,
  Switch,
  Icon,
} from 'antd';
import { inject, observer } from 'mobx-react';

import {
  platforms,
  sellerDiscountColumns,
  sellerProfileForm,
} from './settings';
import { compose } from '../../../services/helpers';
import { Upload } from '../../inputs';
import PhotosUploadForm from './photos-upload-form';
import Divider from '../../divider';
import { Table } from '../../table';
import { PhotosRow } from './styled';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Title } = Typography;

const {
  layout,
  firstNameField,
  lastNameField,
  estateNameField,
  vatField,
  notificationEmailField,
  contactNumberField,
  countryField,
  regionField,
  countriesToSellField,
  addresseeCountryField,
  addresseeStateField,
  dobField,
  descriptionField,
  registeredEstateNameField,
  invoiceAddressField,
  invoiceAdditionalAddressField,
  invoiceCityField,
  invoicePostalCodeField,
  pickUpAddressField,
  pickUpCityField,
  pickUpPostalCodeField,
  billingAddressField,
  billingAdditionalAddressField,
  billingCityField,
  billingPostalCodeField,
  subscriptions: { marketplace, wc, sp },
  platformSelectOptions,
  initialFieldsRequired,
} = sellerProfileForm;

const SellerProfileForm = ({ form, estatesStore, countriesStore }) => {
  const { estate, switchPromo } = estatesStore;
  const {
    profile,
    dob,
    uploadAvatar,
    deleteAvatar,
    uploadBanner,
    deleteBanner,
  } = estate;
  const { sellersCountries, countriesToSell } = countriesStore;
  const [sellerCountry, setSellerCountry] = useState(() => {
    const regionId = profile.regionId;

    if (!regionId) return {};

    let country;

    if (sellersCountries) {
      country = sellersCountries.find(({ regions }) =>
        regions.find(({ id }) => id === regionId)
      );
    }

    return country || {};
  });
  const [fieldsRequired, setFieldsRequired] = useState(initialFieldsRequired);
  const [photosDisplay, setPhotosDisplay] = useState('block');

  const [sellerRegion] = useState(() => {
    const regionId = profile.regionId;

    if (!regionId) return {};

    if (sellerCountry && sellerCountry.regions) {
      return sellerCountry.regions.find(({ id }) => id === regionId);
    }

    return {};
  });

  const onCountryFieldChange = id => {
    const country = sellersCountries.find(c => c.id === id);
    setSellerCountry(country);
    form.resetFields(regionField.name);
  };
  const handlePlatformSelect = value => {
    switch (value) {
      case platforms.marketplace: {
        setFieldsRequired({ description: true, countriesToShip: true });
        setPhotosDisplay('block');
        break;
      }

      case platforms.salesPoint: {
        setFieldsRequired({
          description: false,
          countriesToShip: false,
        });
        setPhotosDisplay('none');
        break;
      }

      case platforms.webConnect: {
        setFieldsRequired({ description: false, countriesToShip: true });
        setPhotosDisplay('none');
        break;
      }
      default: {
        return setFieldsRequired(initialFieldsRequired);
      }
    }
  };

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <TabPane tab="General" key="1">
          <Form layout={layout}>
            <Title level={4}>Profile</Title>
            <Row>
              <Col span={3}>
                <p>Choose your profile photo</p>
              </Col>
              <Col span={6} offset={2}>
                <p>Choose application platform</p>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <Upload
                  styles={{ width: '100%' }}
                  title="Choose your profile photo"
                  resourceId={estate.id}
                  imageUrl={profile.avatar || ''}
                  onUpload={uploadAvatar}
                  onDelete={deleteAvatar}
                />
              </Col>
              <Col span={4} offset={2}>
                <Select
                  defaultValue={platforms.marketplace}
                  styles={{ width: '100%' }}
                  onChange={handlePlatformSelect}
                >
                  {platformSelectOptions.map(platform => (
                    <Option key={platform.value} value={platform.value}>
                      {platform.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={6}>
                <Form.Item label={marketplace.label}>
                  {form.getFieldDecorator(marketplace.name, {
                    initialValue: marketplace.initialValue(profile),
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      defaultChecked={profile.subscription}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={sp.label}>
                  {form.getFieldDecorator(sp.name, {
                    initialValue: sp.initialValue(profile),
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      defaultChecked={profile.sp_subscription}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={wc.label}>
                  {form.getFieldDecorator(wc.name, {
                    initialValue: wc.initialValue(profile),
                  })(
                    <Switch
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      defaultChecked={profile.wc_subscription}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={6}>
                <Form.Item label={estateNameField.label}>
                  {form.getFieldDecorator(estateNameField.name, {
                    rules: estateNameField.rules,
                    initialValue: estateNameField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={countryField.label}>
                  {form.getFieldDecorator(countryField.name, {
                    rules: countryField.rules,
                    initialValue: sellerCountry.id,
                    onChange: onCountryFieldChange,
                  })(
                    <Select>
                      {sellersCountries.map(c => (
                        <Option key={c.id} value={c.id}>
                          {c.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={regionField.label}>
                  {form.getFieldDecorator(regionField.name, {
                    rules: regionField.rules,
                    initialValue: sellerRegion.id,
                  })(
                    <Select>
                      {sellerCountry.regions &&
                        sellerCountry.regions.map(r => (
                          <Option key={r.id} value={r.id}>
                            {r.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={24}>
                <Form.Item label={countriesToSellField.label}>
                  {form.getFieldDecorator(countriesToSellField.name, {
                    rules: [
                      {
                        ...countriesToSellField.rules,
                        required: fieldsRequired.countriesToShip,
                      },
                    ],
                    initialValue: countriesToSellField.initialValue(
                      countriesToSell,
                      profile,
                      countriesToSellField.name
                    ),
                  })(
                    <Select labelInValue mode="multiple">
                      {countriesToSell.map(c => (
                        <Option key={c.id} value={c.id}>
                          {c.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Upload your main photo</p>
              <Col span={24}>
                <Upload
                  styles={{ width: '100%' }}
                  title="Upload banner"
                  resourceId={estate.id}
                  imageUrl={profile.banner || ''}
                  onUpload={uploadBanner}
                  onDelete={deleteBanner}
                />
              </Col>
              <p>Recommended banner resolution is 1920x720px</p>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={descriptionField.label}
                  style={{ marginBottom: 0 }}
                >
                  {form.getFieldDecorator(descriptionField.name, {
                    rules: [
                      {
                        ...descriptionField.rules,
                        required: fieldsRequired.description,
                      },
                    ],
                    initialValue: descriptionField.initialValue(profile),
                  })(<TextArea rows={6} />)}
                  <p>
                    This description will be showed on your custom page, up to
                    1000 characters.
                  </p>
                </Form.Item>
              </Col>
            </Row>
            <PhotosRow styles={{ display: photosDisplay }}>
              <p>Upload some more photos</p>
              <Row type="flex" justify="space-between">
                <PhotosUploadForm estate={estate} profile={profile} />
              </Row>
            </PhotosRow>
            <Divider />
            <Title level={4}>Contact details</Title>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Form.Item label={firstNameField.label}>
                  {form.getFieldDecorator(firstNameField.name, {
                    rules: firstNameField.rules,
                    initialValue: firstNameField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={lastNameField.label}>
                  {form.getFieldDecorator(lastNameField.name, {
                    rules: lastNameField.rules,
                    initialValue: lastNameField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={registeredEstateNameField.label}>
                  {form.getFieldDecorator(registeredEstateNameField.name, {
                    initialValue: registeredEstateNameField.initialValue(
                      profile
                    ),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Form.Item label={vatField.label}>
                  {form.getFieldDecorator(vatField.name, {
                    rules: vatField.rules,
                    initialValue: vatField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={notificationEmailField.label}>
                  {form.getFieldDecorator(notificationEmailField.name, {
                    rules: notificationEmailField.rules,
                    initialValue: notificationEmailField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={contactNumberField.label}>
                  {form.getFieldDecorator(contactNumberField.name, {
                    rules: contactNumberField.rules,
                    initialValue: contactNumberField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider withoutTopMargin />
            <Title level={4}>Invoice details</Title>
            <small>
              The invoice address will be used as a pick up and billing address
              if those fields are left blank
            </small>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item label={invoiceAddressField.label}>
                  {form.getFieldDecorator(invoiceAddressField.name, {
                    rules: invoiceAddressField.rules,
                    initialValue: invoiceAddressField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={invoiceAdditionalAddressField.label}>
                  {form.getFieldDecorator(invoiceAdditionalAddressField.name, {
                    initialValue: invoiceAdditionalAddressField.initialValue(
                      profile
                    ),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item label={invoiceCityField.label}>
                  {form.getFieldDecorator(invoiceCityField.name, {
                    rules: invoiceCityField.rules,
                    initialValue: invoiceCityField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={invoicePostalCodeField.label}>
                  {form.getFieldDecorator(invoicePostalCodeField.name, {
                    rules: invoicePostalCodeField.rules,
                    initialValue: invoicePostalCodeField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider withoutTopMargin />
            <Title level={4}>Pick-up details</Title>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Form.Item label={pickUpAddressField.label}>
                  {form.getFieldDecorator(pickUpAddressField.name, {
                    initialValue: pickUpAddressField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={pickUpCityField.label}>
                  {form.getFieldDecorator(pickUpCityField.name, {
                    initialValue: pickUpCityField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={pickUpPostalCodeField.label}>
                  {form.getFieldDecorator(pickUpPostalCodeField.name, {
                    initialValue: pickUpPostalCodeField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider withoutTopMargin />
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item label={addresseeCountryField.label}>
                  {form.getFieldDecorator(addresseeCountryField.name, {
                    initialValue: sellerCountry.name,
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={addresseeStateField.label}>
                  {form.getFieldDecorator(addresseeStateField.name, {
                    rules: addresseeStateField.rules,
                    initialValue: addresseeStateField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider withoutTopMargin />
            <Row>
              <Col span={5}>
                <Form.Item label={dobField.label}>
                  {form.getFieldDecorator(dobField.name, {
                    rules: dobField.rules,
                    initialValue: dob,
                  })(<Input placeholder={dobField.placeholder} />)}
                </Form.Item>
              </Col>
            </Row>
            <Divider withoutTopMargin />
            <Title level={4}>Billing details</Title>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item label={billingAddressField.label}>
                  {form.getFieldDecorator(billingAddressField.name, {
                    initialValue: billingAddressField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={billingAdditionalAddressField.label}>
                  {form.getFieldDecorator(billingAdditionalAddressField.name, {
                    initialValue: billingAdditionalAddressField.initialValue(
                      profile
                    ),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item label={billingCityField.label}>
                  {form.getFieldDecorator(billingCityField.name, {
                    initialValue: billingCityField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={billingPostalCodeField.label}>
                  {form.getFieldDecorator(billingPostalCodeField.name, {
                    initialValue: billingPostalCodeField.initialValue(profile),
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="Promo" key="2">
          <Table
            columns={sellerDiscountColumns(switchPromo)}
            data={estate.promo}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default compose(
  Form.create(),
  inject(({ rootStore }) => ({
    countriesStore: rootStore.countriesStore,
    estatesStore: rootStore.estatesStore,
    rootStore,
  })),
  observer
)(SellerProfileForm);
