import React from 'react';

import { StyledModal } from './styled';

const Modal = ({
  title,
  visible,
  confirmLoading,
  destroyOnClose,
  width,
  onOk,
  onCancel,
  children,
  footer,
  header,
}) => (
  <StyledModal
    title={title}
    visible={visible}
    confirmLoading={confirmLoading}
    destroyOnClose={destroyOnClose}
    width={width}
    onOk={onOk}
    onCancel={onCancel}
    footer={footer}
    header={header}
  >
    {children}
  </StyledModal>
);

export default Modal;
