import { action, observable } from 'mobx';

class WineGroups {
  @observable types = [];
  @observable grapeSorts = [];
  @observable styles = [];
  @observable organics = [];
  @observable forFood = [];
  @observable forCooking = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound onFetchAll(data) {
    Object.assign(this, data);
  }

  @action.bound async fetchAll() {
    const {
      getGroups: { method, url },
    } = this.rootStore.urls.products;

    return await this.rootStore.makeRequest(this.onFetchAll, method, url);
  }
}

export default WineGroups;
