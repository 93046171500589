import { action, observable, computed } from 'mobx';
import { ExportToCsv } from 'export-to-csv';

import Buyer from '../models/Buyer';
import { optionsCSV } from '../pages/buyers/settings';

class Buyers {
  @observable rawBuyers = [];
  @observable rawBuyer = {};
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get buyers() {
    return this.rawBuyers;
  }

  @computed
  get buyer() {
    return this.rawBuyer;
  }

  @action.bound onFetchAll(data) {
    this.rawBuyers = data.raw.map(b => new Buyer(b, this.rootStore));
    this.total = data.total;
  }

  @action.bound async fetchAll() {
    const {
      getAllBuyers: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(this.onFetchAll, method, url(15));
  }

  @action.bound findOne(id) {
    return this.boundedFindOne.bind(this, id);
  }

  @action.bound async boundedFindOne(id) {
    this.rawBuyer = this.rawBuyers.find(b => b.id === id);
  }

  @action.bound delete(id) {
    return this.boundedDelete.bind(this, id);
  }

  @action.bound async boundedDelete(id) {
    const {
      delete: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(
      this.onDelete,
      method,
      `${url}/${id}`,
      { id }
    );
  }

  @action.bound onDelete(_, { id }) {
    this.rawBuyers = this.rawBuyers.filter(b => b.id !== id);
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLowerCase();
    this.searchIn = field;

    return await this.searchBuyers(this.searchIn, this.searchValue);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';

    return await this.fetchAll();
  }

  @action.bound onChangePagination(data) {
    this.rawBuyers = data.raw.map(b => new Buyer(b, this.rootStore));
    this.total = data.total;
  }

  @action.bound async changePagination(page, pageSize) {
    if (!this.searchValue || !this.searchIn) {
      const { method, url } = this.rootStore.urls.users.getBuyersPageData;
      return await this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize)
      );
    }

    return await this.searchBuyers(this.searchIn, this.searchValue, page);
  }

  @action.bound onExport(data) {
    const csvExporter = new ExportToCsv(optionsCSV);

    csvExporter.generateCsv(data);
  }

  @action.bound async exportCSV() {
    const { method, url } = this.rootStore.urls.users.getBuyersExport;
    return await this.rootStore.makeRequest(this.onExport, method, url);
  }

  async searchBuyers(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.users.searchBuyers;
    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, 15, page)
    );
  }
}

export default Buyers;
