import { action, computed, observable } from 'mobx';
import Discount from '../models/Discount';

class Discounts {
  @observable rawData = [];
  @observable rawDiscount = {};
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get discounts() {
    return this.rawData;
  }

  @computed
  get discount() {
    return this.rawDiscount;
  }

  @action.bound onFetchAll(data) {
    this.rawData = data.raw;
    this.total = data.total;
  }

  @action.bound onChangePagination(data) {
    this.rawData = data.raw;
    this.total = data.total;
  }

  @action.bound async changePagination(page, pageSize) {
    if (!this.searchValue || !this.searchIn) {
      const { method, url } = this.rootStore.urls.discounts.getDiscountsPageData;
      return await this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize),
      );
    }

    return this.searchDiscounts(this.searchIn, this.searchValue, page);
  }

  @action.bound deleteDiscount(discountId) {
    return this.boundedDeleteDiscount.bind(this, discountId);
  }

  @action.bound async onCreate() {
    return this.fetchAll();
  }

  @action.bound async createDiscount(body) {
    const { method, url } = this.rootStore.urls.discounts.createDiscount;

    return this.rootStore.makeRequest(this.onCreate, method, url, body);
  }

  @action.bound async boundedDeleteDiscount(discountId) {
    const { method, url } = this.rootStore.urls.discounts.deleteDiscount;

    return this.rootStore.makeRequest(
      this.onDeleteDiscount,
      method,
      url(discountId),
    );
  }

  @action.bound onDeleteDiscount({ id }) {
    this.rawData = this.rawData.filter(discount => discount.id !== id);
  }

  @action.bound onUpdateDiscount(discountId) {
    return (data) => {
      this.rawData = this.rawData.map(discount => {
        if (discount.id === discountId) {
          return new Discount(data, this.rootStore);
        }

        return discount;
      });
    };
  }

  @action.bound async updateDiscount(discountId, body) {
    const { method, url } = this.rootStore.urls.discounts.updateDiscount;

    return this.rootStore.makeRequest(
      this.onUpdateDiscount(discountId),
      method,
      url(discountId),
      body,
    );
  }

  @action.bound async fetchAll() {
    const { method, url } = this.rootStore.urls.discounts.getAll;

    return this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(15),
    );
  }

  @action.bound findOne(id) {
    return this.boundedFindOne.bind(this, id);
  }

  @action.bound async boundedFindOne(id) {
    this.rawDiscount = this.rawData.find(discount => discount.id === id);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';

    return this.fetchAll();
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLowerCase();
    this.searchIn = field;

    return await this.searchDiscounts(this.searchIn, this.searchValue);
  }

  async searchDiscounts(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.discounts.searchDiscounts;
    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, 15, page)
    );
  }
}

export default Discounts;
