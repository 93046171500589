import React from 'react';
import {
  Form,
  Select,
  Typography,
  Input,
  InputNumber,
  DatePicker,
  Row,
  Col,
} from 'antd';
import { discountForm } from './settings';
import moment from 'moment';
import { DISCOUNT_OWNER } from '../../../pages/discounts/settings';

const { Option } = Select;
const { Title } = Typography;

const {
  layout,
  title,
  codeNameField,
  discountTypeField,
  promoTypeField,
  marketplaceAmountField,
  sellerAmountField,
  expirationDateField,
  discountStatusField,
  productQuantityField,
  countField,
} = discountForm;

const discountTypeOptions = [
  { name: 'Fixed', value: 'fixed' },
  { name: 'Percent', value: 'percent' },
];

const promoTypeOptions = [
  { name: 'Marketplace', value: 'marketplace' },
  { name: 'Sales point/Web shop', value: 'sales-point/web-shop' },
];

const discountStatusOptions = [
  { name: 'Active', value: 1 },
  { name: 'Inactive', value: 0 },
];

const DiscountForm = ({ form, discount }) => {
  const discountTypeInitialValue =
    discountTypeField.initialValue(discount) || discountTypeOptions[0].value;
  const expirationDateInitialValue = moment(
    expirationDateField.initialValue(discount)
  ).isValid()
    ? expirationDateField.initialValue(discount)
    : null;

  return (
    <Form layout={layout}>
      <Title level={4}>{title}</Title>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item label={codeNameField.label}>
            {form.getFieldDecorator(codeNameField.name, {
              rules: codeNameField.rules,
              initialValue: codeNameField.initialValue(discount),
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={discountTypeField.label}>
            {form.getFieldDecorator(discountTypeField.name, {
              rules: discountTypeField.rules,
              initialValue: discountTypeInitialValue,
            })(
              <Select >
                {discountTypeOptions.map(d => (
                  <Option key={d.value} value={d.value}>
                    {d.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={promoTypeField.label}>
            {form.getFieldDecorator(promoTypeField.name, {
              rules: promoTypeField.rules,
              initialValue: promoTypeField.initialValue(discount),
            })(
              <Select mode="multiple">
                {promoTypeOptions.map(p => (
                  <Option key={p.value} value={p.value}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item label={discountStatusField.label}>
            {form.getFieldDecorator(discountStatusField.name, {
              rules: discountStatusField.rules,
              initialValue: Number(discountStatusField.initialValue(discount)),
            })(
              <Select>
                {discountStatusOptions.map(p => (
                  <Option key={p.value} value={p.value}>
                    {p.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={productQuantityField.label}>
            {form.getFieldDecorator(productQuantityField.name, {
              rules: productQuantityField.rules,
              initialValue: productQuantityField.initialValue(discount),
            })(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={countField.label}>
            {form.getFieldDecorator(countField.name, {
              rules: countField.rules,
              initialValue: countField.initialValue(discount),
            })(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item label={expirationDateField.label}>
            {form.getFieldDecorator(expirationDateField.name, {
              rules: expirationDateField.rules,
              initialValue: expirationDateInitialValue,
            })(
              <DatePicker
                style={{ width: '100%' }}
                placeholder={expirationDateField.placeholder}
                format={expirationDateField.dateFormat}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={sellerAmountField.label}>
            {form.getFieldDecorator(sellerAmountField.name, {
              rules: sellerAmountField.rules,
              initialValue: sellerAmountField.initialValue(discount),
            })(<InputNumber min={0} style={{ width: '100%' }} />)}
          </Form.Item>
        </Col>
        {(discount && discount.owner === DISCOUNT_OWNER.avelyn) ||
          !discount ? (
          <Col span={7}>
            <Form.Item label={marketplaceAmountField.label}>
              {form.getFieldDecorator(marketplaceAmountField.name, {
                rules: marketplaceAmountField.rules,
                initialValue: marketplaceAmountField.initialValue(discount),
              })(<InputNumber min={0} style={{ width: '100%' }} />)}
            </Form.Item>
          </Col>
        ) : (
          <Col span={7}></Col>
        )}
      </Row>
    </Form>
  );
};

export default Form.create()(DiscountForm);
