import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Row, Col, Icon } from 'antd';
import moment from 'moment';

import { Table } from '../../components/table';
import Button from '../../components/button';
import Modal from '../../components/modal';
import {
  columns,
  tableActions,
  deliveryColumns,
  productColumns,
  modals,
  OrderStatus,
  paymentStatuses,
} from './settings';
import { compose } from '../../services/helpers';
import { useModal } from '../../common';
import { StyledTextArea } from '../../components/text-area/styled';
import { PickUpForm } from '../../components/forms';

const { RangePicker } = DatePicker;
const Icons = {
  send: () => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
          <path
            fill="currentColor"
            d="m21.433 4.861l-6 15.5a1 1 0 0 1-1.624.362l-3.382-3.235l-2.074 2.073a.5.5 0 0 1-.853-.354v-4.519L2.309 9.723a1 1 0 0 1 .442-1.691l17.5-4.5a1 1 0 0 1 1.181 1.329ZM19 6.001L8.032 13.152l1.735 1.66L19 6Z"
          />
        </g>
      </svg>
    </div>
  ),
  cancel: () => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12 20.5c-4.688 0-8.5-3.812-8.5-8.5s3.812-8.5 8.497-8.5c4.69 0 8.503 3.812 8.503 8.5s-3.812 8.5-8.5 8.5m0-15c-3.586 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5s-2.916-6.5-6.5-6.5m.003 3A3.502 3.502 0 0 1 15.5 12c0 .206-.02.412-.057.615l-4.057-4.059c.203-.036.408-.056.614-.056m.003-1a4.48 4.48 0 0 0-2.39.697l6.188 6.188a4.448 4.448 0 0 0 .699-2.387A4.5 4.5 0 0 0 12.003 7.5m-3.446 3.884l4.059 4.06A3.504 3.504 0 0 1 8.5 11.998c0-.206.02-.412.057-.614m-.358-1.773a4.48 4.48 0 0 0-.699 2.387A4.502 4.502 0 0 0 12 16.5a4.48 4.48 0 0 0 2.387-.699z"
        />
      </svg>
    </div>
  ),
};
const Orders = ({ ordersStore }) => {
  const {
    orders,
    fetchAll,
    total,
    changePagination,
    updateOrderNotes,
    resetSearch,
    order,
    findOne,
    cancelOrder,
    confirmOrder,
    downloadOrderDocuments,
    requestOrderPickUp,
    generateInvoices,
  } = ordersStore;

  const [
    isOrderCancelModalShown,
    onSetIsOrderCancelModalShown,
    onOrderCancelOkAction,
    onOrderCancelCancelAction,
  ] = useModal();

  const [
    isOrderConfirmModalShown,
    onSetIsOrderConfirmModalShown,
    onOrderConfirmOkAction,
    onOrderConfirmCancelAction,
  ] = useModal();

  const [
    isOrderPickUpModalShown,
    onSetIsOrderPickUpModalShown,
    onOrderPickUpOkAction,
    onOrderPickUpCancelAction,
  ] = useModal();

  const [
    isBuyerModalShown,
    onSetIsBuyerModalShown,
    onBuyerCancelAction,
  ] = useModal();

  const [
    isSendMailModalShown,
    onSetIsSendMailModalShown,
    onSendMailOkAction,
    onSendMailCancelAction,
  ] = useModal();

  const [
    isProductsModalShown,
    onSetIsProductsModalShown,
    onProductsCancelAction,
  ] = useModal();

  const pickUpForm = useRef({});

  const [buyer, setBuyer] = useState(null);
  const [products, setProducts] = useState([]);
  const [dateFrom, setDateFrom] = useState('all');
  const [dateTo, setDateTo] = useState('all');

  useEffect(() => {
    const onMount = async () => {
      await fetchAll(dateFrom, dateTo);
    };

    onMount();

    return resetSearch;
  }, [fetchAll, dateFrom, dateTo, resetSearch]);

  const onShowBuyer = ({ deliveryAddress, buyer }) => () => {
    if (!deliveryAddress && buyer) {
      return setBuyer([{ ...buyer, pickupPointName: 'None' }]);
    }

    if (deliveryAddress) {
      try {
        const address = JSON.parse(deliveryAddress);
        return setBuyer([
          {
            ...buyer,
            ...address,
            pickupPointName: address.pickupPointName || address.name,
            id: address.name,
          },
        ]);
      } catch (e) {
        setBuyer(null);
      }
    }
  };

  const onShowAddressClose = () => setTimeout(() => setBuyer(null), 300);

  const onShowProducts = ({ products, comment, transactionId, buyer }) => () =>
    setProducts(
      (products || []).map(p => ({
        ...p,
        comment,
        transactionId,
        buyerState: buyer.state,
      }))
    );

  const onShowProductsClose = () => setTimeout(() => setProducts(null), 300);

  const onDateRangeChange = (_, dateStrings) => {
    setDateFrom(dateStrings[0] || 'all');
    setDateTo(dateStrings[1] || 'all');
  };

  const onExportCSV = async () => {
    await ordersStore.exportCSV(dateFrom, dateTo);
  };

  const onChangePagination = async (page, pageSize) => {
    await changePagination(page, pageSize, dateFrom, dateTo);
  };

  const onRequestOrderPickUp = () =>
    pickUpForm.current.validateFields(async (error, values) => {
      if (error) return;

      const { closeTime, readyTime, pickupDate } = values;
      const modifiedCloseTime = `${closeTime.hour()}:${closeTime.minutes()}`;
      const modifiedReadyTime = `${readyTime.hour()}:${readyTime.minutes()}`;
      const modifiedPickupDate = pickupDate.format('YYYY-MM-DD');
      const body = {
        readyTime: modifiedReadyTime,
        closeTime: modifiedCloseTime,
        pickupDate: modifiedPickupDate,
      };

      await requestOrderPickUp(order.id, body);
      onOrderPickUpCancelAction()();
    });

  const renderActions = row => {
    const { orderStatus, id, pickupRequestNumber, paymentStatus } = row;

    const cancelOrderButton =
      orderStatus === OrderStatus.NEW ? (
        <div>
          <Button
            type={tableActions.cancelOrderButton.type}
            icon={tableActions.cancelOrderButton.icon}
            color="#ff7875"
            onClick={onSetIsOrderCancelModalShown(findOne(id))}
          >
            {tableActions.cancelOrderButton.title}
          </Button>
        </div>
      ) : null;

    const confirmOrderButton =
      orderStatus === OrderStatus.NEW &&
      ['customerbehalfpaid'].includes(paymentStatus) ? (
        <div>
          <Button
            type={tableActions.confirmOrderButton.type}
            icon={tableActions.confirmOrderButton.icon}
            color="#32a852"
            onClick={onSetIsOrderConfirmModalShown(findOne(id))}
          >
            {tableActions.confirmOrderButton.title}
          </Button>
        </div>
      ) : null;

    const downloadOrderDocumentsButton =
      orderStatus !== OrderStatus.NEW &&
      orderStatus !== OrderStatus.CANCELED ? (
        <div>
          <Button
            type={tableActions.downloadDocumentsButton.type}
            icon={tableActions.downloadDocumentsButton.icon}
            onClick={downloadOrderDocuments(id)}
          >
            {tableActions.downloadDocumentsButton.title}
          </Button>
        </div>
      ) : null;

    const requestOrderPickUpButton =
      orderStatus === OrderStatus.IN_PROGRESS && !pickupRequestNumber ? (
        <div>
          <Button
            type={tableActions.requestPickUpButton.type}
            icon={tableActions.requestPickUpButton.icon}
            onClick={onSetIsOrderPickUpModalShown(findOne(id))}
          >
            {tableActions.requestPickUpButton.title}
          </Button>
        </div>
      ) : null;

    const generateInvoicesButton = (
      <div>
        <Button
          type={tableActions.generateInvoicesButton.type}
          icon={tableActions.generateInvoicesButton.icon}
          onClick={onSetIsSendMailModalShown(findOne(id))}
        >
          {tableActions.generateInvoicesButton.title}
        </Button>
      </div>
    );

    return (
      <>
        {generateInvoicesButton}
        {downloadOrderDocumentsButton}
        {requestOrderPickUpButton}
        <div>
          <Button
            type={tableActions.buyerAndDeliveryInfo.type}
            icon={tableActions.buyerAndDeliveryInfo.icon}
            onClick={onSetIsBuyerModalShown(onShowBuyer(row))}
          >
            {tableActions.buyerAndDeliveryInfo.title}
          </Button>
        </div>
        <div>
          <Button
            type={tableActions.orderedProducts.type}
            icon={tableActions.orderedProducts.icon}
            onClick={onSetIsProductsModalShown(onShowProducts(row))}
          >
            {tableActions.orderedProducts.title}
          </Button>
        </div>
        {cancelOrderButton}
        {confirmOrderButton}
      </>
    );
  };

  const renderNotes = row => {
    const { notes, id } = row;
    let changedNotes = notes || '';

    const onChangeNotes = e => {
      changedNotes = e.target.value;
    };

    return (
      <>
        <div>
          <StyledTextArea
            rows={3}
            defaultValue={notes}
            onChange={onChangeNotes}
          />
          <Button
            type={tableActions.orderNotes.type}
            icon={tableActions.orderNotes.icon}
            onClick={() => {
              updateOrderNotes(id, changedNotes);
            }}
          >
            {tableActions.orderNotes.title}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        width="30%"
        visible={isOrderPickUpModalShown}
        destroyOnClose
        onOk={onOrderPickUpOkAction(onRequestOrderPickUp)}
        onCancel={onOrderPickUpCancelAction()}
      >
        <PickUpForm ref={pickUpForm} />
      </Modal>
      <Modal
        title={modals.cancel.title}
        visible={isOrderCancelModalShown}
        width="30%"
        destroyOnClose
        onOk={onOrderCancelOkAction(cancelOrder(order.id))}
        onCancel={onOrderCancelCancelAction()}
      >
        {modals.cancel.inner(order.orderId)}
      </Modal>
      <Modal
        title={modals.confirm.title}
        visible={isOrderConfirmModalShown}
        width="30%"
        destroyOnClose
        onOk={onOrderConfirmOkAction(confirmOrder(order.id))}
        onCancel={onOrderConfirmCancelAction()}
      >
        {modals.confirm.inner(order.orderId)}
      </Modal>
      <Modal
        title={tableActions.buyerAndDeliveryInfo.title}
        visible={isBuyerModalShown}
        width="70%"
        destroyOnClose
        onOk={onBuyerCancelAction(onShowAddressClose)}
        onCancel={onBuyerCancelAction(onShowAddressClose)}
      >
        <Table columns={deliveryColumns} data={buyer} />
      </Modal>
      <Modal
        title={''}
        visible={isSendMailModalShown}
        width="500px"
        footer={null}
        header={null}
      >
        {tableActions.sendMail.inner()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={onSendMailOkAction(generateInvoices(order.id, true))}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '5px' }}
            >
              <Icons.send />
              {tableActions.sendMail.actions.ok.title}
            </div>
          </Button>
          <Button
            onClick={onSendMailCancelAction(generateInvoices(order.id, false))}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '5px' }}
            >
              <Icons.cancel />
              {tableActions.sendMail.actions.cancel.title}
            </div>
          </Button>
        </div>
      </Modal>
      <Modal
        title={tableActions.orderedProducts.title}
        visible={isProductsModalShown}
        width="70%"
        destroyOnClose
        onOk={onProductsCancelAction(onShowProductsClose)}
        onCancel={onProductsCancelAction(onShowProductsClose)}
      >
        <Table columns={productColumns} data={products} />
      </Modal>
      <Row type="flex" style={{ marginBottom: '15px' }}>
        <Col span={5}>
          <Row type="flex" align="middle" style={{ flexWrap: 'nowrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>Select date range: </span>
            <RangePicker
              style={{ marginLeft: '10px' }}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
              onChange={onDateRangeChange}
            />
          </Row>
        </Col>
        <Col offset={1} span={3}>
          <Button color="#1890ff" onClick={onExportCSV}>
            {'Export CSV'}
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        data={orders}
        renderActions={renderActions}
        renderNotes={renderNotes}
        pagination
        paginationTotal={total}
        onChangePagination={onChangePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    ordersStore: rootStore.ordersStore,
  })),
  observer
)(Orders);
