import React from 'react';
import { Icon } from 'antd';

import { StyledPreloader } from './styled';

const Preloader = () => (
  <StyledPreloader>
    <Icon type="loading" />
  </StyledPreloader>
);

export default Preloader;
