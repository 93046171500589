export const theme = 'light';
export const mode = 'inline';
export const menuItems = [
  {
    title: 'Estates',
    icon: 'user',
    href: '/estates',
  },
  {
    title: 'Buyers',
    icon: 'user',
    href: '/buyers',
  },
  {
    title: 'Products',
    icon: 'barcode',
    href: '/products',
  },
  {
    title: 'Orders',
    icon: 'transaction',
    href: '/orders',
  },
  {
    title: 'Reports',
    icon: 'file-text',
    href: '/reports',
  },
  {
    title: 'Promo',
    icon: 'dollar',
    href: '/promo',
  },
  {
    title: 'Alerts',
    icon: 'bell',
    href: '/alerts',
  },
];
