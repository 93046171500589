import styled from 'styled-components';

export const StyledClickableCellWrapper = styled.div`
  color: #1890ff;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
