import React from 'react';

import { StyledUpsStatusCell } from './styled';

const UpsStatusCell = ({ upsStatus, orderStatus }) => (
  <StyledUpsStatusCell orderStatus={orderStatus}>
    {upsStatus}
  </StyledUpsStatusCell>
);

export default UpsStatusCell;
