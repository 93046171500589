import React, { useCallback, useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, DatePicker } from 'antd';
import moment from 'moment';

import { Table } from '../../components/table';
import Modal from '../../components/modal';
import { columns, tableActions, modals } from './settings';
import { compose } from '../../services/helpers';
import {
  SellerBasicForm,
  SellerProfileForm,
  SellerEmailUpdateForm,
} from '../../components/forms';
import Button from '../../components/button';
import { useModal } from '../../common';
import { createButton } from '../../components/table/settings';

const { RangePicker } = DatePicker;

const Sellers = ({ estatesStore, countriesStore, location, history }) => {
  const {
    estates,
    estate,
    fetchAll: fetchAllEstates,
    findOne,
    create,
    update,
    delete: deleteEstate,
    resetSearch,
    total,
    changePagination,
  } = estatesStore;
  const { fetchAll: fetchAllCountries } = countriesStore;
  const [dateFrom, setDateFrom] = useState('all');
  const [dateTo, setDateTo] = useState('all');

  const sellerBasicForm = useRef({});
  const sellerEmailUpdateForm = useRef({});
  const sellerUpdateForm = useRef({});

  const [
    isCreateModalShown,
    onSetIsCreateModalShown,
    onCreateOkAction,
    onCreateCancelAction,
  ] = useModal();
  const [
    isUpdateEmailModalShown,
    onSetIsUpdateEmailModalShown,
    onUpdateEmailOkAction,
    onUpdateEmailCancelAction,
  ] = useModal();
  const [
    isUpdateModalShown,
    onSetIsUpdateModalShown,
    onUpdateOkAction,
    onUpdateCancelAction,
  ] = useModal();
  const [
    isDeleteModalShown,
    onSetIsDeleteModalShown,
    onDeleteOkAction,
    onDeleteCancelAction,
  ] = useModal();
  const urlChanged = useCallback(() => {
    return location.pathname !== history.location.pathname;
  }, [history.location.pathname, location.pathname]);

  useEffect(() => {
    const onMount = async () => {
      await Promise.all([
        fetchAllCountries(),
        fetchAllEstates(dateFrom, dateTo),
      ]);
    };

    onMount();

    return () => urlChanged() && resetSearch();
  }, [
    fetchAllCountries,
    fetchAllEstates,
    dateFrom,
    dateTo,
    resetSearch,
    urlChanged,
  ]);

  const onUpdate = () =>
    sellerUpdateForm.current.validateFields(async (err, values) => {
      if (err) return;

      await update({ ...values, id: estate.id });
      onUpdateCancelAction()();
    });

  const onEmailUpdate = () =>
    sellerEmailUpdateForm.current.validateFields(async (err, values) => {
      if (err) return;

      await estatesStore.updateEmail({ id: estate.id, ...values });
      onUpdateEmailCancelAction()();
    });

  const onCreate = () =>
    sellerBasicForm.current.validateFields(async (err, values) => {
      if (err) return;

      // todo: add field 'emailConfirm' to the create estate form
      await create({ ...values, emailConfirm: values.email, terms: 'true' });
      onCreateCancelAction()();
    });

  const onDateRangeChange = (_, dateStrings) => {
    setDateFrom(dateStrings[0] || 'all');
    setDateTo(dateStrings[1] || 'all');
  };

  const onExportCSV = async () => {
    await estatesStore.exportCSV(dateFrom, dateTo);
  };


  const onExportWineriesCSV = async () => {
    await estatesStore.exportWineriesCSV();
  }

  const copyTastingRoomUrl = id => () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_TASTING_ROOM_CLIENT_URL}/${id}`
    );
  };

  const copyWebConnectUrl = id => () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEB_CONNECT_CLIENT_URL}/${id}`
    );
  };

  const renderActions = row => (
    <>
      <div>
        <Button
          type={tableActions.copyTastingRoomURL.type}
          icon={tableActions.copyTastingRoomURL.icon}
          onClick={copyTastingRoomUrl(row.profile.estateUrlName)}
        >
          {tableActions.copyTastingRoomURL.title}
        </Button>
      </div>
      <div>
        <Button
          type={tableActions.copyWebConnectURL.type}
          icon={tableActions.copyWebConnectURL.icon}
          onClick={copyWebConnectUrl(row.profile.estateUrlName)}
        >
          {tableActions.copyWebConnectURL.title}
        </Button>
      </div>
      <div>
        <Button
          type={tableActions.updateEmailButton.type}
          icon={tableActions.updateEmailButton.icon}
          onClick={onSetIsUpdateEmailModalShown(findOne(row.id))}
        >
          {tableActions.updateEmailButton.title}
        </Button>
      </div>
      <div>
        <Button
          type={tableActions.updateProfileButton.type}
          icon={tableActions.updateProfileButton.icon}
          onClick={onSetIsUpdateModalShown(findOne(row.id))}
        >
          {tableActions.updateProfileButton.title}
        </Button>
      </div>
      <div>
        <Button
          type={tableActions.deleteButton.type}
          icon={tableActions.deleteButton.icon}
          color="#ff7875"
          onClick={onSetIsDeleteModalShown(findOne(row.id))}
        >
          {tableActions.deleteButton.title}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Modal
        title={modals.create.title}
        visible={isCreateModalShown}
        width="40%"
        destroyOnClose
        onOk={onCreateOkAction(onCreate)}
        onCancel={onCreateCancelAction()}
      >
        <SellerBasicForm ref={sellerBasicForm} />
      </Modal>
      <Modal
        title={modals.updateEmail.title}
        visible={isUpdateEmailModalShown}
        width="40%"
        destroyOnClose
        onOk={onUpdateEmailOkAction(onEmailUpdate)}
        onCancel={onUpdateEmailCancelAction()}
      >
        <SellerEmailUpdateForm ref={sellerEmailUpdateForm} entity={estate} />
      </Modal>
      <Modal
        title={modals.updateProfile.title}
        visible={isUpdateModalShown}
        width="70%"
        destroyOnClose
        onOk={onUpdateOkAction(onUpdate)}
        onCancel={onUpdateCancelAction()}
      >
        <SellerProfileForm ref={sellerUpdateForm} />
      </Modal>
      <Modal
        title={modals.delete.title}
        visible={isDeleteModalShown}
        width="30%"
        destroyOnClose
        onOk={onDeleteOkAction(deleteEstate(estate.id))}
        onCancel={onDeleteCancelAction()}
      >
        {modals.delete.inner(estate.fullName || estate.email)}
      </Modal>
      <Row type="flex" style={{ marginBottom: '15px' }}>
        <Col span={5}>
          <Row type="flex" align="middle" style={{ flexWrap: 'nowrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>Select date range: </span>
            <RangePicker
              style={{ marginLeft: '10px' }}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
              onChange={onDateRangeChange}
            />
          </Row>
        </Col>
        <Col offset={1} span={1}>
          <Button color="#1890ff" onClick={onExportCSV}>
            {'Export CSV'}
          </Button>
        </Col>
        <Col offset={1} span={2}>
          <Button color="#1890ff" onClick={onExportWineriesCSV}>
            {'Export Wineries CSV'}
          </Button>
        </Col>
        <Col span={2}>
          <Button
            type={createButton.type}
            icon={createButton.icon}
            onClick={onSetIsCreateModalShown()}
          >
            {createButton.title}
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        data={estates}
        renderActions={renderActions}
        pagination
        paginationTotal={total}
        onChangePagination={changePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    estatesStore: rootStore.estatesStore,
    countriesStore: rootStore.countriesStore,
  })),
  observer
)(Sellers);
