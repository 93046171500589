import styled from 'styled-components';
import { Layout, Menu, Avatar } from 'antd';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  > button + button {
    margin-left: 16px;
  }
`;

export const StyledMenu = styled(Menu)`
  display: flex;
  height: 100%;
  align-items: center;
  border-bottom: none;

  &.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
  }

  &.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
  }

  &.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }

  &.ant-menu-horizontal > .ant-menu-item-active,
  &.ant-menu-horizontal > .ant-menu-item-open,
  &.ant-menu-horizontal > .ant-menu-item-selected,
  &.ant-menu-horizontal > .ant-menu-item:hover,
  &.ant-menu-horizontal > .ant-menu-submenu-active,
  &.ant-menu-horizontal > .ant-menu-submenu-open,
  &.ant-menu-horizontal > .ant-menu-submenu-selected,
  &.ant-menu-horizontal > .ant-menu-submenu:hover {
    border-bottom: none;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const StyledProfileTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUsername = styled.div`
  & .greeting {
    color: '#999';
    margin-right: 4px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin-left: 8px;
`;
