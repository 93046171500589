import React from 'react';
import { Form, Button, Icon, Row, Col } from 'antd';

import { loginForm } from './settings';
import { StyledInput } from './styled';

const {
  layout,
  emailField,
  passwordField,
  submitButton,
  iconStyle,
} = loginForm;

const LoginForm = ({ form, pending, onSubmit }) => (
  <Form layout={layout} onSubmit={onSubmit}>
    <Form.Item>
      {form.getFieldDecorator(emailField.name, {
        rules: emailField.rules,
      })(
        <StyledInput
          prefix={<Icon type={emailField.prefix.type} style={iconStyle} />}
          type={emailField.type}
          placeholder={emailField.placeholder}
        />
      )}
    </Form.Item>
    <Form.Item>
      {form.getFieldDecorator(passwordField.name, {
        rules: passwordField.rules,
      })(
        <StyledInput
          prefix={<Icon type={passwordField.prefix.type} style={iconStyle} />}
          type={passwordField.type}
          placeholder={passwordField.placeholder}
        />
      )}
    </Form.Item>
    <Row>
      <Col push={16} span={8}>
        <Button
          type={submitButton.type}
          htmlType={submitButton.htmlType}
          loading={pending}
          style={submitButton.style}
          size={submitButton.size}
        >
          {submitButton.title}
        </Button>
      </Col>
    </Row>
  </Form>
);

export default LoginForm;
