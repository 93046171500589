import { observable, action, computed } from 'mobx';

import { fillMissingImages } from '../services/helpers';

const ESTATE_PHOTOS_AMOUNT = 3;

const DEFAULT_PROFILE = {
  firstName: '',
  lastName: '',
  avatar: '',
  banner: '',
  photos: [],
  notificationEmail: null,
  phoneNumber: null,
  description: null,
  regionId: null,
  estateName: null,
  vat: null,
  address: null,
  additionalAddress: '',
  addresseeCountryId: null,
  addresseeState: null,
  addresseeCity: null,
  addresseePostalCode: null,
  stripeAccountId: null,
  dob: null,
  frontIdPhoto: '',
  backIdPhoto: '',
  additionalIdPhoto: '',
  pickupAddress: '',
  pickupCity: '',
  pickupPostalCode: '',
  billingAddress: '',
  billingAdditionalAddress: '',
  billingCity: '',
  billingPostalCode: '',
  subscription: null,
  sp_subscription: null,
  wc_subscription: null,
};

class Estate {
  id = '';
  @observable email = '';
  @observable profile = DEFAULT_PROFILE;

  constructor(estate, rootStore) {
    if (!Object.keys(estate.profile)) {
      estate.profile = DEFAULT_PROFILE;
    }

    fillMissingImages({
      entity: estate,
      path: 'profile.photos',
      correctAmount: ESTATE_PHOTOS_AMOUNT,
    });

    Object.assign(this, estate);
    this.rootStore = rootStore;
  }

  @computed
  get dob() {
    const { dob } = this.profile;

    if (dob) {
      const values = dob.split('-');
      return `${values[2]}/${values[1]}/${values[0]}`;
    }
    return '';
  }

  @action.bound onUpdateAvatar(responseData) {
    this.profile.avatar = responseData;
  }

  @action.bound async uploadAvatar({ file }, id) {
    const {
      uploadAvatar: { method, url },
    } = this.rootStore.urls.users;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('avatar', file);

    return await this.rootStore.makeRequest(
      this.onUpdateAvatar,
      method,
      url,
      formData
    );
  }

  @action.bound onDeleteAvatar() {
    this.profile.avatar = '';
  }

  @action.bound async deleteAvatar(id) {
    const {
      deleteAvatar: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(
      this.onDeleteAvatar,
      method,
      `${url}/${id}`
    );
  }

  @action.bound onUpdateBanner(responseData) {
    this.profile.banner = responseData;
  }

  @action.bound async uploadBanner({ file }, id) {
    const {
      uploadBanner: { method, url },
    } = this.rootStore.urls.users;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('banner', file);

    return await this.rootStore.makeRequest(
      this.onUpdateBanner,
      method,
      url,
      formData
    );
  }

  @action.bound onDeleteBanner() {
    this.profile.banner = '';
  }

  @action.bound async deleteBanner(id) {
    const {
      deleteBanner: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(
      this.onDeleteBanner,
      method,
      `${url}/${id}`
    );
  }

  @action.bound onUpdatePhoto(responseData, requestData) {
    const index = requestData.get('index');
    this.profile.photos[+index] = responseData;
  }

  @action.bound async uploadPhoto({ file }, id, index) {
    const {
      uploadPhoto: { method, url },
    } = this.rootStore.urls.users;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('index', index);
    formData.append('photo', file);

    return await this.rootStore.makeRequest(
      this.onUpdatePhoto,
      method,
      url,
      formData
    );
  }

  @action.bound onDeletePhoto(_, requestData) {
    const { index } = requestData;
    this.profile.photos[+index] = '';
  }

  @action.bound async deletePhoto(id, index) {
    const {
      deletePhoto: { method, url },
    } = this.rootStore.urls.users;

    return this.rootStore.makeRequest(
      this.onDeletePhoto,
      method,
      `${url}/${index}/${id}`,
      {
        id,
        index,
      }
    );
  }
}

export default Estate;
