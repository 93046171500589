export const createButton = {
  title: 'Create',
  type: 'primary',
  icon: 'plus',
};

export const tableRowKey = 'id';

export const search = {
  searchButton: {
    title: 'Search',
    type: 'primary',
    icon: 'search',
    size: 'small',
  },
  resetButton: {
    title: 'Reset',
    size: 'small',
  },
  icon: {
    type: 'search',
    filteredColor: '#1890ff',
  },
};
