import styled from 'styled-components';

const colorMapPromoStatuses = {
  new: 'black',
  onReview: 'grey',
  approved: 'green',
  canceled: 'red',
};

export const StyledRatingStatusCell = styled.div`
  width: 150px;
  height: 96px;
  margin: -16px;
  padding-top: 37px;
  color: ${({ status }) => colorMapPromoStatuses[status]};
  font-weight: bold;
  text-align: center;
`;
