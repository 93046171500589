import { observable, action, computed } from 'mobx';

class Countries {
  @observable rawSellersCountries = [];
  @observable rawCountriesToSell = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get sellersCountries() {
    return this.rawSellersCountries;
  }

  @computed
  get countriesToSell() {
    return this.rawCountriesToSell;
  }

  @action.bound setSellerCountries(data) {
    this.rawSellersCountries = data;
  }

  @action.bound setCountriesToSell(data) {
    this.rawCountriesToSell = data;
  }

  @action.bound async fetchAll() {
    const {
      getSellerCountries: {
        method: sellerCountriesMethod,
        url: sellerCountriesUrl,
      },
      getCountriesToSell: {
        method: countriesToSellMethod,
        url: countriesToSellUrl,
      },
    } = this.rootStore.urls.countries;

    return await Promise.all([
      await this.rootStore.makeRequest(
        this.setSellerCountries,
        sellerCountriesMethod,
        sellerCountriesUrl
      ),
      await this.rootStore.makeRequest(
        this.setCountriesToSell,
        countriesToSellMethod,
        countriesToSellUrl
      ),
    ]);
  }
}

export default Countries;
