import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Row, Col, Select } from 'antd';
import moment from 'moment';

import { Table } from '../../components/table';
import Button from '../../components/button';
import { columns, selectOptions } from './settings';
import { compose } from '../../services/helpers';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Reports = ({ reportsStore }) => {
  const [dateFrom, setDateFrom] = useState('all');
  const [dateTo, setDateTo] = useState('all');
  const [destination, setDestination] = useState(selectOptions[0].value);

  useEffect(() => {
    const onMount = async () => {
      await reportsStore.fetchAll(destination, dateFrom, dateTo);
    };

    onMount();
  }, [dateFrom, dateTo, destination, reportsStore]);

  const onExportCSV = async () => {
    await reportsStore.exportCSV(destination, dateFrom, dateTo);
  };

  const onDateRangeChange = (_, dateStrings) => {
    setDateFrom(dateStrings[0] || 'all');
    setDateTo(dateStrings[1] || 'all');
  };

  const onSelectChange = value => {
    setDestination(value);
  };

  return (
    <>
      <Row style={{ marginBottom: '15px' }}>
        <Col span={7}>
          <Row type="flex" align="middle" style={{ flexWrap: 'nowrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>Select date range: </span>
            <RangePicker
              style={{ width: '100%', marginLeft: '10px' }}
              ranges={{
                Today: [moment(), moment()],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
              onChange={onDateRangeChange}
            />
          </Row>
        </Col>
        <Col offset={1} span={5}>
          <Row type="flex" align="middle" style={{ flexWrap: 'nowrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>Report by: </span>
            <Select
              defaultValue={selectOptions[0].name}
              style={{ width: '100%', marginLeft: '10px' }}
              onChange={onSelectChange}
            >
              {selectOptions.map(o => (
                <Option key={o.name} value={o.value}>
                  {o.name}
                </Option>
              ))}
            </Select>
          </Row>
        </Col>
        <Col offset={1} span={3}>
          <Button color="#1890ff" onClick={onExportCSV}>
            {'Export CSV'}
          </Button>
        </Col>
      </Row>
      <Table rowKey="country" columns={columns} data={reportsStore.data} />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    reportsStore: rootStore.reportsStore,
  })),
  observer
)(Reports);
