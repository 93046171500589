import React from 'react';
import { Form, Input } from 'antd';

import { sellerEmailUpdateForm } from './settings';

const { layout, emailField } = sellerEmailUpdateForm;

const SellerEmailUpdateForm = ({ form, entity }) => (
  <Form layout={layout}>
    <Form.Item label={emailField.label}>
      {form.getFieldDecorator(emailField.name, {
        rules: emailField.rules,
        initialValue: entity
          ? entity[emailField.name]
          : emailField.defaultValue,
      })(<Input type={emailField.type} />)}
    </Form.Item>
  </Form>
);

export default Form.create()(SellerEmailUpdateForm);
