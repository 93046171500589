import { get } from 'lodash';
import { ratingStatuses } from '../../../pages/products/settings';

import { takeChecked, getVintageYears } from '../../../services/helpers';

const getInitialValue = (field, defaultValue = '') => data => {
  const value = get(data, field);
  return value || defaultValue;
};

const getInitialValueForMultipleSelect = (allValues, checkedValues) =>
  takeChecked(allValues, checkedValues).map(v => ({ ...v, key: v.id }));

const getInitialValueForSelect = (allValues, checkedValues) =>
  takeChecked(allValues, checkedValues).map(({ id }) => id)[0] || null;

const getRatingSystemRules = options => {
  return [
    {
      required: true,
      validator(_, value) {
        return options.some(prodRate => prodRate.id === value);
      },
      message: 'Required field',
    },
  ];
};

const getScoresOptions = (ratingSystem, rate) => {
  const ratingType = ratingSystem.find(
    ratingType => ratingType.id === rate.systemId
  );
  return ratingType ? ratingType.options : [];
};

export const WINE_VOLUME_ENUMS = [750];

export const VINTAGE_ENUMS = getVintageYears();

export const productForm = {
  layout: 'vertical',
  userField: {
    label: 'Estate',
    name: 'user',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('user.id'),
  },
  nameField: {
    label: 'Name',
    name: 'name',
    rules: [
      {
        required: true,
        type: 'string',
        min: 3,
        max: 130,
        message: 'Required field. Must be 3 and no longer than 130 characters',
      },
    ],
    initialValue: getInitialValue('name'),
  },
  vintageField: {
    label: 'Vintage',
    name: 'vintage',
    rules: [
      {
        required: true,
        type: 'enum',
        enum: VINTAGE_ENUMS.map(({ id }) => id),
        validator(_, value) {
          return !!VINTAGE_ENUMS.find(v => v.id === value);
        },
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('vintage'),
  },
  wineTypeField: {
    label: 'Wine type',
    name: 'wineType',
    rules: [
      {
        type: 'number',
        required: true,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('wineType.id'),
  },
  grapeSortField: {
    label: 'Grape sort',
    name: 'grapeSorts',
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValueForMultipleSelect,
  },
  wineStylesField: {
    label: 'Wine styles',
    name: 'styles',
    rules: [{ type: 'number', required: true, message: 'Required field' }],
    initialValue: getInitialValueForSelect,
  },
  organicAndMoreField: {
    label: 'Organic and more',
    name: 'organics',
    rules: [{ type: 'array' }],
    initialValue: getInitialValueForMultipleSelect,
  },
  fishAndMeatField: {
    label: 'Fish, meat and vegetables',
    name: 'forFood',
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValueForMultipleSelect,
  },
  cookingStylesField: {
    label: 'Cooking styles',
    name: 'forCooking',
    rules: [
      {
        required: true,
        type: 'array',
        min: 1,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValueForMultipleSelect,
  },
  priceField: {
    label: 'Price',
    name: 'price',
    rules: [
      {
        required: true,
        type: 'number',
        transform(value) {
          return +value;
        },
        validator(_, value) {
          return value > 0;
        },
        message: 'Required field. Must not be less than 1 euro',
      },
    ],
    initialValue: getInitialValue('price'),
  },
  descriptionField: {
    label: 'Description',
    name: 'description',
    rules: [
      {
        required: true,
        type: 'string',
        min: 1,
        max: 1000,
        message:
          'Required field. Should not be empty and more than 1000 characters',
      },
    ],
    initialValue: getInitialValue('description'),
  },
  volumeField: {
    label: 'Volume',
    name: 'volume',
    rules: [
      {
        type: 'enum',
        required: true,
        enum: WINE_VOLUME_ENUMS,
        validator(_, value) {
          return WINE_VOLUME_ENUMS.includes(value);
        },
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('volume'),
  },
  alcoholField: {
    label: 'Alcohol (%)',
    name: 'alcohol',
    rules: [
      {
        type: 'number',
        required: true,
        transform(value) {
          return +value;
        },
        validator(_, value) {
          return value > 0 && value <= 15;
        },
        message: 'Required field. Must be not less than 1 and not more than 15',
      },
    ],
    initialValue: getInitialValue('alcohol'),
  },
  ratingTypeField: {
    label: 'System',
    name: 'rating-type',
    rules: getRatingSystemRules,
    initialValue: getInitialValueForSelect,
  },
  ratingScoreField: {
    label: 'Rating Scores',
    name: 'rating-scores',
    rules: (ratingSystem, rate) => {
      const options = getScoresOptions(ratingSystem, rate);

      return getRatingSystemRules(options);
    },
    initialValue: (ratingSystem, rate) => {
      const options = getScoresOptions(ratingSystem, rate);

      return getInitialValueForSelect(options, [{ id: rate.scoreId }]);
    },
    getSystemScores: (ratingSystem, rate) => {
      return getScoresOptions(ratingSystem, rate);
    },
  },
  ratingReferenceField: {
    label: 'Reference',
    name: 'rating-input',
    rules: [
      {
        type: 'string',
        required: true,
        min: 1,
        max: 1000,
        message:
          'Required field. Should not be empty and more than 1000 characters',
      },
    ],
    initialValue: getInitialValue('reference'),
  },
  deleteButton: {
    type: 'link',
    icon: 'delete',
  },
  addRatingButton: {
    type: 'primary',
    icon: 'plus',
    size: 'large',
    shape: 'circle',
    label: 'Add wine rating',
    styles: { background: '#b81928', border: 0, color: '#FFFFFF' },
  },
  purchasableField: {
    label: 'Set purchasable',
    name: 'purchasable',
  },
  ratingActiveField: {
    label: 'Set rating active',
    name: 'ratingActive',
  },
  ratingStatusField: {
    label: 'Set Rating Status',
    name: 'ratingStatus',
    options: ratingStatuses,
    rules: [
      {
        required: true,
        validator(_, value) {
          return ratingStatuses.some(status => status.value === value);
        },
        message: 'Required field',
      },
    ],
    initialValue: ratingStatus => {
      const status = ratingStatuses.find(
        status => status.value === ratingStatus
      );

      return status ? status.value : '';
    },
  },
};
