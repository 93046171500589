import React from 'react';
import { Button as AntdButton } from 'antd';

const Button = ({
  type = 'default',
  color = '',
  icon = '',
  children,
  disabled,
  onClick,
  shape,
  style = {},
}) => (
  <AntdButton
    type={type}
    icon={icon}
    onClick={onClick}
    disabled={disabled}
    shape={shape}
    style={{ color, ...style }}
  >
    {children}
  </AntdButton>
);

export default Button;
