import React from 'react';
import { Icon } from 'antd';

import { StyledDeleteButton } from './styled';

const DeleteButton = ({ onClick }) => (
  <StyledDeleteButton className="image-upload-delete-btn" onClick={onClick}>
    <Icon type="close" />
  </StyledDeleteButton>
);

export default DeleteButton;
