import React from 'react';
import { Button } from 'antd';
import { inject, observer } from 'mobx-react';

import { StyledHeader } from './styled-wrapper';
import { compose } from '../../services/helpers';

const Header = ({ authStore, sitemapStore }) => (
  <StyledHeader>
    {/* <Button icon="download" onClick={sitemapStore.exportSitemap}>Download site map</Button> */}
    <Button shape="circle" icon="logout" onClick={authStore.logout} />
  </StyledHeader>
);

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    sitemapStore: rootStore.sitemapStore,
  })),
  observer
)(Header);
