import React from 'react';
import { Layout as AntdLayout } from 'antd';

import Logo from '../logo';
import Menu from '../menu';
import { breakpoint, collapsedWidth } from './settings';

const { Sider } = AntdLayout;

const Sidebar = () => (
  <Sider
    breakpoint={breakpoint}
    collapsedWidth={collapsedWidth}
    style={{ backgroundColor: '#fff' }}
  >
    <Logo />
    <Menu />
  </Sider>
);

export default Sidebar;
