import React from 'react';
import { Col, Form } from 'antd';
import { inject, observer } from 'mobx-react';

import { row, redirectUrl } from './settings';
import { StyledRow } from './styled';
import { LoginForm } from '../../components/forms';
import { compose } from '../../services/helpers';
import Logo from '../../components/logo';

const Login = ({ form, authStore, rootStore }) => {
  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields(async (err, data) => {
      if (!err) {
        await authStore.login(data);
        rootStore.routingStore.push(redirectUrl);
      }
    });
  };

  return (
    <StyledRow type={row.type} justify={row.justify} align={row.align}>
      <Col span={6}>
        <Logo size="large" />
        <LoginForm
          onSubmit={handleSubmit}
          form={form}
          pending={rootStore.pending}
        />
      </Col>
    </StyledRow>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore,
  })),
  Form.create(),
  observer
)(Login);
