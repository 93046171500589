import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { compose } from '../../../services/helpers';

const LoginRoute = ({ authStore, ...restProps }) => {
  const { component: Page, ...routeProps } = restProps;

  return (
    <Route
      {...routeProps}
      render={props =>
        authStore.data.token ? <Redirect to="/estates" /> : <Page {...props} />
      }
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
  })),
  observer
)(LoginRoute);
