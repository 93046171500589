import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu as AntdMenu, Icon } from 'antd';
import { inject, observer } from 'mobx-react';

import { theme, mode, menuItems } from './settings';
import { compose } from '../../services/helpers';

const Menu = ({ location }) => (
  <AntdMenu
    theme={theme}
    mode={mode}
    selectedKeys={[location.pathname]}
    style={{ border: 'none' }}
  >
    {menuItems.map(({ title, icon, href }) => (
      <AntdMenu.Item key={href}>
        <Link to={href}>
          <Icon type={icon} />
          {title}
        </Link>
      </AntdMenu.Item>
    ))}
  </AntdMenu>
);

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore,
  })),
  withRouter,
  observer
)(Menu);
