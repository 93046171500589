import React from 'react';
import { Col } from 'antd';
import { observer } from 'mobx-react';

import { Upload } from '../../inputs';
import { compose } from '../../../services/helpers';

const PhotosUploadForm = ({
  estate: { id, uploadPhoto, deletePhoto },
  profile: { photos },
}) =>
  photos.map((photo, i) => (
    <Col key={i} span={7}>
      <Upload
        styles={{ width: '100%' }}
        title={`Upload photo ${i + 1}`}
        index={i}
        resourceId={id}
        imageUrl={photo || ''}
        onUpload={uploadPhoto}
        onDelete={deletePhoto}
      />
    </Col>
  ));

export default compose(observer)(PhotosUploadForm);
