export const jpegMimeType = 'image/jpeg';
export const pngMimeType = 'image/png';
export const maxFileSize = 5;
export const fileTypeError = 'You can only upload JPG/PNG file!';
export const fileSizeError = `Image must be smaller than ${maxFileSize}MB!`;
export const statusList = {
  uploading: 'uploading',
  done: 'done',
};
export const listType = 'picture-card';
export const imageAlt = 'image';
