import { action, observable, computed } from 'mobx';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

import Estate from '../models/Estate';
import { optionsCSV } from '../pages/sellers/settings';

class Estates {
  @observable rawEstates = [];
  @observable rawEstate = {};
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get estates() {
    return this.rawEstates;
  }

  @computed
  get estate() {
    return this.rawEstate;
  }

  @action.bound onFetchAll(data) {
    this.rawEstates = data.raw.map(e => new Estate(e, this.rootStore));
    this.total = data.total;
  }

  @action.bound async fetchAll(dateFrom, dateTo) {
    const {
      getAllEstates: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(15, dateFrom, dateTo)
    );
  }

  @action.bound onCreate(data) {
    this.rawEstates = [
      new Estate(
        {
          ...data,
          createdAt: moment(data.createdAt).format('DD/MMM/YYYY'),
          updatedAt: moment(data.updatedAt).format('DD/MMM/YYYY'),
        },
        this.rootStore
      ),
      ...this.rawEstates,
    ];
  }

  @action.bound async create(body) {
    const {
      create: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(this.onCreate, method, url, body);
  }

  @action.bound findOne(id) {
    return this.boundedFindOne.bind(this, id);
  }

  @action.bound async boundedFindOne(id) {
    this.rawEstate = this.rawEstates.find(e => e.id === id);
  }

  @action.bound delete(id) {
    return this.boundedDelete.bind(this, id);
  }

  @action.bound onDelete(_, { id }) {
    this.rawEstates = this.rawEstates.filter(e => e.id !== id);
  }

  @action.bound async boundedDelete(id) {
    const {
      delete: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(
      this.onDelete,
      method,
      `${url}/${id}`,
      { id }
    );
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLowerCase().trim();
    this.searchIn = field;

    return await this.searchSellers(this.searchIn, this.searchValue);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';

    return await this.fetchAll();
  }

  @action.bound async update(rawData) {
    const {
      updateProfile: { method, url },
    } = this.rootStore.urls.users;

    const data = Object.keys(rawData).reduce((acc, key) => {
      if (key === 'countryIds') {
        return { ...acc, [key]: rawData.countryIds.map(({ key }) => key) };
      } else if (key === 'addresseeCountryId') {
        return { ...acc, [key]: rawData.countryId };
      }

      return { ...acc, [key]: rawData[key] };
    }, {});

    delete data.countryId;
    console.log(data)
    return await this.rootStore.makeRequest(this.onUpdate, method, url, {
      id: this.id,
      ...data,
    });
  }

  @action.bound onSubscriptionUpdate({ userId, ...subscriptions }) {
    this.rawEstates = this.rawEstates.map(e => {
      if (e.id === userId) {
        return new Estate(
          {
            ...e,
            isSubscriptionChanged: false,
            profile: { ...e.profile, ...subscriptions },
          },
          this.rootStore
        );
      }
      return e;
    });
  }

  @action.bound onUpdate(responseData) {
    console.log(responseData)
    this.rawEstates = this.rawEstates.map(e => {
      if (e.id === responseData.id) {
        return new Estate(responseData, this.rootStore);
      }
      return e;
    });
  }

  @action.bound onEmailUpdate(responseData) {
    this.rawEstates = this.rawEstates.map(estate => {
      if (estate.id === responseData.id) {
        return { ...estate, email: responseData.email };
      }

      return estate;
    });
  }

  @action.bound async updateEmail(data) {
    const {
      updateEmail: { method, url },
    } = this.rootStore.urls.users;

    return await this.rootStore.makeRequest(this.onEmailUpdate, method, url, {
      id: this.id,
      ...data,
    });
  }

  @action.bound onChangePagination(data) {
    this.rawEstates = data.raw.map(e => new Estate(e, this.rootStore));
    this.total = data.total;
  }

  @action.bound async changePagination(page, pageSize) {
    if (!this.searchIn || !this.searchValue) {
      const { method, url } = this.rootStore.urls.users.getSellersPageData;
      return await this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize)
      );
    }

    return await this.searchSellers(this.searchIn, this.searchValue, page);
  }

  @action.bound onExport(data) {
    const csvExporter = new ExportToCsv(optionsCSV);

    csvExporter.generateCsv(data);
  }
  @action.bound onWineriesExport(data) {
    const csvExporter = new ExportToCsv(optionsCSV);
    data = data.map((winery)=> {
      const _url = `${winery.url}`
      delete winery.url
    return ({
      'Estate Name': winery['Estate Name'],
      'Owner Name': winery['Owner Name'],
      'Email Address': winery['Email Address'],
      'Status': winery['Status'],
      'Sales Point URL': `${process.env.REACT_APP_TASTING_ROOM_CLIENT_URL}/${_url}`,
      'Webshop Connect URL': `${process.env.REACT_APP_WEB_CONNECT_CLIENT_URL}/${_url}`,
    })
  })
    csvExporter.generateCsv(data);
  }

  @action.bound async exportCSV(dateFrom, dateTo) {
    const { method, url } = this.rootStore.urls.users.getSellersExport;
    return this.rootStore.makeRequest(
      this.onExport,
      method,
      url(dateFrom, dateTo)
    );
  }

  @action.bound async exportWineriesCSV() {
    const { method, url } = this.rootStore.urls.users.getWineriesExport;
    return this.rootStore.makeRequest(
      this.onWineriesExport,
      method,
      url()
    );
  }

  @action.bound switchPromo(userDiscountId) {
    return async value => {
      const { method, url } = this.rootStore.urls.discounts.switchUserDiscount;

      return this.rootStore.makeRequest(
        () => {},
        method,
        url(userDiscountId, value)
      );
    };
  }

  @action.bound changeSubscription = (name, id) => async (value, event) => {
    const { url, method } = this.rootStore.urls.users.updateSubscription;

    return this.rootStore.makeRequest(this.onUpdateSubscription, method, url, {
      [name]: value,
      userId: id,
    });
  };

  @action.bound onUpdateSubscription({ userId, ...updatedProps }) {
    this.rawEstates = this.rawEstates.map(estate => {
      if (estate.id === userId) {
        const newEstateDate = {
          ...estate,
          profile: { ...estate.profile, ...updatedProps },
        };

        return new Estate(newEstateDate, this.rootStore);
      }

      return estate;
    });
  }

  async searchSellers(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.users.searchSellers;
    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, 15, page)
    );
  }
}

export default Estates;
