import { observable, action } from 'mobx';

import { fillMissingImages } from '../services/helpers';

const PRODUCT_IMAGES_AMOUNT = 5;

class Product {
  @observable id = '';
  @observable name = '';
  @observable price = '';
  @observable description = '';
  @observable images = [];
  @observable volume = null;
  @observable alcohol = '';
  @observable vintage = null;
  @observable purchasable = true;
  @observable categories = [];
  @observable wineType = {};
  @observable ratings = [];
  @observable ratingActive = false;
  @observable enableAdding = true;
  @observable ratingStatus = null;

  minRatingsRows = 2;

  constructor(product, rootStore) {
    fillMissingImages({
      entity: product,
      path: 'images',
      correctAmount: PRODUCT_IMAGES_AMOUNT,
    });

    Object.assign(this, product);
    this.rootStore = rootStore;
  }

  get ratignRow() {
    return {
      systemId: 0,
      scoreId: 0,
      referrence: '',
    };
  }

  @action.bound onUpdateImages(responseData, requestData) {
    const index = requestData.get('index');
    this.images[+index] = responseData;
  }

  @action.bound async uploadImage({ file }, id = null, index) {
    const {
      uploadImage: { method, url },
    } = this.rootStore.urls.products;

    const formData = new FormData();
    formData.append('index', index);
    formData.append('image', file);

    return await this.rootStore.makeRequest(
      this.onUpdateImages,
      method,
      url,
      formData
    );
  }

  @action.bound deleteRating = deletedInd => () => {
    this.ratings.length <= this.minRatingsRows
      ? this.deleteAll()
      : this.deleteByIndex(deletedInd);
  };

  @action.bound addRating() {
    this.ratings.length
      ? this.ratings.push(this.ratignRow)
      : this.ratings.push(this.ratignRow, this.ratignRow);
  }

  @action.bound changeRatingType = changedInd => value => {
    const changedRating = this.ratings.find(
      (rating, ratingIndex) => ratingIndex === changedInd
    );

    changedRating.systemId = value;
    changedRating.scoreId = 0;
  };

  @action.bound changeScores = changedInd => value => {
    const changedRating = this.ratings.find(
      (rating, ratingIndex) => ratingIndex === changedInd
    );

    changedRating.scoreId = value;
  };

  @action.bound changeRatingReference = changedInd => event => {
    const changedRating = this.ratings.find(
      (rating, ratingIndex) => ratingIndex === changedInd
    );

    changedRating.reference = event.target.value;
  };

  @action.bound onDeleteImage(_, requestData) {
    const { index } = requestData;
    this.images[+index] = '';
  }

  @action.bound async deleteImage(id, index) {
    const {
      deleteImageFromProduct: {
        method: fromProductMethod,
        url: fromProductUrl,
      },
      deleteImage: { method, url },
    } = this.rootStore.urls.products;

    return await this.rootStore.makeRequest(
      this.onDeleteImage,
      id ? fromProductMethod : method,
      id
        ? `${fromProductUrl}/${id}/${index}`
        : `${url}?url=${this.images[index]}`,
      {
        id,
        index,
      }
    );
  }

  deleteAll() {
    this.ratings.length = 0;
  }

  deleteByIndex(index) {
    this.ratings = this.ratings.filter(
      (rating, ratingIndex) => ratingIndex !== index
    );
  }
}

export default Product;
