import React from 'react';
import { get } from 'lodash';

import { useModal } from '../../../common';
import { Table } from '../index';
import Modal from '../../modal';
import { StyledClickableCellWrapper } from './Styled';

const ClickableCell = ({
  data,
  dataIndex,
  dataKey = '',
  modalTitle,
  modalWidth = '70%',
  columns,
}) => {
  const [isModalShown, onSetIsModalShown, onCancelAction] = useModal();
  const tableData = dataKey ? [data[dataKey]] : [data];

  return (
    <StyledClickableCellWrapper>
      <Modal
        title={modalTitle}
        visible={isModalShown}
        width={modalWidth}
        destroyOnClose
        onOk={onCancelAction()}
        onCancel={onCancelAction()}
      >
        <Table columns={columns} data={tableData} />
      </Modal>
      <span onClick={onSetIsModalShown()}>{get(data, dataIndex)}</span>
    </StyledClickableCellWrapper>
  );
};

export default ClickableCell;
