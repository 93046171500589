import React from 'react';

import { StyledRatingStatusCell } from './styled';
import { getStatusTitle } from './settings';

const RatingStatusCell = ({ ratingStatus }) => (
  <StyledRatingStatusCell status={ratingStatus}>
    {getStatusTitle(ratingStatus)}
  </StyledRatingStatusCell>
);

export default RatingStatusCell;
