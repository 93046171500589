export const pickUpForm = {
  layout: 'vertical',
  title: 'Pick up date',
  preferredTimeField: {
    label: 'Preferred pick up time',
    name: 'readyTime',
    rules: [
      {
        required: true,
        message: 'Required field',
      },
    ],
  },
  closingTimeField: {
    label: 'Closing time',
    name: 'closeTime',
    rules: [
      {
        required: true,
        message: 'Required field',
      },
    ],
  },
  preferredDateField: {
    label: 'Preferred pick up date',
    name: 'pickupDate',
    rules: [
      {
        required: true,
        message: 'Required field',
      },
    ],
  },
};
