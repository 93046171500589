import styled from 'styled-components';
import { Popover } from 'antd';

export const StyledPopover = styled(Popover)`
  margin-left: 5px;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-around;
`;
