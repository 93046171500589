import React from 'react';
import { Table as AntdTable, Row, Col } from 'antd';

import Button from '../button';
import { createButton, tableRowKey } from './settings';
import { titleToUpperCase } from '../../services/helpers';
import Pagination from '../pagination';

const Table = ({
  columns,
  data,
  pagination = false,
  paginationTotal,
  onChangePagination,
  rowKey,
  onCreate,
  onCSVExport,
  renderActions,
  renderNotes,
}) => {
  const renderColumns = () => {
    if (!renderActions) return columns.map(titleToUpperCase);
    const columnsWithActions = [
      ...columns,
      {
        title: 'action',
        key: 'action',
        align: 'center',
        render: (_, row) => renderActions(row),
      },
    ];

    renderNotes &&
      columnsWithActions.push({
        title: 'Notes',
        dataIndex: 'notes',
        align: 'center',
        render: (_, row) => renderNotes(row),
      });

    return columnsWithActions.map(c => {
      if (typeof c.title === 'string') {
        return titleToUpperCase(c);
      }
      return c;
    });
  };

  return (
    <>
      {onCSVExport || onCreate ? (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '24px',
          }}
        >
          {onCSVExport && (
            <Col span={2}>
              <Button color="#1890ff" onClick={onCSVExport}>
                {'Export CSV'}
              </Button>
            </Col>
          )}

          {onCreate && (
            <Col span={2}>
              <Button
                type={createButton.type}
                icon={createButton.icon}
                onClick={onCreate}
              >
                {createButton.title}
              </Button>
            </Col>
          )}
        </Row>
      ) : null}
      <AntdTable
        rowKey={rowKey || tableRowKey}
        pagination={false}
        columns={renderColumns()}
        dataSource={data}
      />
      {pagination ? (
        <Pagination total={paginationTotal} onChange={onChangePagination} />
      ) : null}
    </>
  );
};

export default Table;
