import React from 'react';
import { DatePicker, Form, Typography, TimePicker } from 'antd';
import moment from 'moment';
import { pickUpForm } from './settings';

const { Title } = Typography;

const {
  layout,
  title,
  preferredTimeField,
  closingTimeField,
  preferredDateField,
} = pickUpForm;

const PickUpForm = ({ form }) => {
  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days').endOf('day');
  };
  return (
    <Form layout={layout}>
      <Title level={4}>{title}</Title>
      <Form.Item label={preferredTimeField.label}>
        {form.getFieldDecorator(preferredTimeField.name, {
          rules: preferredTimeField.rules
        })(
          <TimePicker
            style={{ width: '100%' }}
            format="HH:mm"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showTime={{
              defaultValue: moment("00:00", "HH:mm"),
              format: "HH:mm",
            }}
          />
        )}
      </Form.Item>
      <Form.Item label={closingTimeField.label}>
        {form.getFieldDecorator(closingTimeField.name, {
          rules: closingTimeField.rules
        })(
          <TimePicker
            style={{ width: '100%' }}
            format="HH:mm"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showTime={{
              defaultValue: moment("00:00", "HH:mm"),
              format: "HH:mm"
            }}
          />
        )}
      </Form.Item>
      <Form.Item label={preferredDateField.label}>
        {form.getFieldDecorator(preferredDateField.name, {
          rules: preferredDateField.rules
        })(
          <DatePicker
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
          />
        )}
      </Form.Item>
    </Form>
  );
};

export default Form.create()(PickUpForm);
