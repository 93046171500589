import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';

import { StyledPopover, StyledButtonWrapper } from './styled';
import { compose } from '../../../services/helpers';

const Search = ({
  dataIndex,
  storeName,
  rootStore,
  type = 'text',
  selectOptions = [],
}) => {
  const [searchBy, setSearchBy] = useState('');
  const [visible, setVisible] = useState(false);
  const store = rootStore[storeName];

  const onSearch = e => {
    if (e.type === 'click' || e.keyCode === 13) {
      store.search(searchBy, dataIndex);
      triggerVisibility();
    }
  };

  const onResetSearch = () => {
    setSearchBy('');
    store.resetSearch();
    triggerVisibility();
  };

  const onChange = e => {
    if (e && e.target) {
      return setSearchBy(e.target.value);
    }
    setSearchBy(e);
  };

  const triggerVisibility = () => setVisible(v => !v);

  const renderInput = type => {
    switch (type) {
      case 'text': {
        return (
          <Input value={searchBy} onChange={onChange} onKeyUp={onSearch} />
        );
      }
      case 'select': {
        return renderSelect(selectOptions);
      }
      default:
        return null;
    }
  };

  const renderSelect = options => {
    return (
      <Select style={{ width: '200px' }} onSelect={onChange} value={searchBy}>
        {options.map(o => (
          <Select.Option key={o.value} value={o.value}>
            {o.title}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const content = (
    <>
      {renderInput(type)}
      <StyledButtonWrapper>
        <Button size="small" type="primary" onClick={onSearch}>
          Search
        </Button>
        <Button size="small" onClick={onResetSearch}>
          Reset
        </Button>
      </StyledButtonWrapper>
    </>
  );

  return (
    <StyledPopover
      content={content}
      placement="bottom"
      trigger="click"
      visible={visible}
    >
      <Button
        type="link"
        size="small"
        shape="circle"
        icon="search"
        onClick={triggerVisibility}
      />
    </StyledPopover>
  );
};

export default compose(
  inject(({ rootStore }) => ({ rootStore })),
  observer
)(Search);
