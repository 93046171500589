import { get, isNil } from 'lodash';
import moment from 'moment';

const getInitialValue = (field, defaultValue = '') => data => {
  const value = get(data, field);
  return !isNil(value) ? value : defaultValue;
};
const getMultipleInitialValue = (field, defaultValue = '') => data => {
  const value = get(data, field);
  return value ? [...value] : [];
};

export const discountForm = {
  layout: 'vertical',
  title: 'Discount',
  codeNameField: {
    label: 'Code name',
    name: 'code',
    rules: [
      {
        required: true,
        type: 'string',
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('code'),
  },
  discountTypeField: {
    label: 'Type',
    name: 'type',
    rules: [
      {
        type: 'string',
        required: true,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('type'),
  },
  promoTypeField: {
    label: 'Promo type',
    name: 'promoType',
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Required field',
      },
    ],
    initialValue: getMultipleInitialValue('promoType'),
  },
  discountStatusField: {
    label: 'Discount status',
    name: 'isActive',
    rules: [
      {
        type: 'number',
      },
    ],
    initialValue: getInitialValue('isActive'),
  },
  marketplaceAmountField: {
    label: 'Avelyn Share',
    name: 'marketplaceAmount',
    rules: [
      {
        type: 'number',
        required: 'true',
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('marketplaceAmount'),
  },
  sellerAmountField: {
    label: 'Seller Share',
    name: 'sellerAmount',
    rules: [
      {
        type: 'number',
        required: true,
        message: 'Required field',
      },
    ],
    initialValue: getInitialValue('sellerAmount'),
  },
  expirationDateField: {
    label: 'Expiration date',
    name: 'expirationDate',
    rules: [{ required: true, message: 'Required field' }],
    placeholder: 'Select expiration date',
    dateFormat: 'DD/MM/YYYY',
    initialValue: value => {
      return moment(getInitialValue('expirationDate')(value));
    },
  },
  productQuantityField: {
    label: 'Product amount',
    name: 'productQuantity',
    rules: [{ type: 'number', required: true, message: 'Required field' }],
    initialValue: getInitialValue('productQuantity'),
  },
  countField: {
    label: 'Discount count',
    name: 'count',
    rules: [
      {
        type: 'number',
        message: 'If set, discount count should be greater than -1',
        min: 0,
      },
    ],
    initialValue: getInitialValue('count'),
  },
};
