import React from 'react';

import { normalizeMoneyValue } from '../../services/helpers';
import { TableComponents } from '../../components/table';

const buyerColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Postal code',
    dataIndex: 'postalCode',
  },
];

const sellerColumns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    width: 200,
  },
  {
    title: 'Estate name',
    dataIndex: 'estateName',
    align: 'center',
  },
  {
    title: 'Notification email',
    dataIndex: 'notificationEmail',
    align: 'center',
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    align: 'center',
  },
  {
    title: 'Vat',
    dataIndex: 'vat',
    align: 'center',
  },
  {
    title: 'Stripe account id',
    dataIndex: 'stripeAccountId',
    align: 'center',
  },
];

export const deliveryColumns = [
  {
    title: 'Pickup point',
    dataIndex: 'pickupPointName',
  },
  {
    title: "Buyer's name",
    dataIndex: 'fullName',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Postal code',
    dataIndex: 'postalCode',
  },
];

export const productColumns = [
  {
    title: 'Transaction id',
    dataIndex: 'transactionId',
    width: 150,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Estate price',
    dataIndex: 'estatePrice',
    render: (_, { estatePrice }) => normalizeMoneyValue(estatePrice),
    align: 'center',
  },
  {
    title: 'Sparkling',
    dataIndex: 'sparkling',
    align: 'center',
    render: (_, { sparkling }) => (sparkling ? 'Yes' : 'No'),
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    align: 'center',
  },
  {
    title: 'Alcohol',
    dataIndex: 'alcohol',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'center',
  },
  {
    title: "Buyer's state",
    dataIndex: 'buyerState',
  },
  {
    title: "Buyer's comment",
    dataIndex: 'comment',
  },
];

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="ORD ID">
        <TableComponents.Search dataIndex="orderId" storeName="ordersStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'orderId',
    align: 'center',
    width: 150,
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="ORGN">
        <TableComponents.Search
          dataIndex="sellerCountry.iso2Code"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'sellerCountry.iso2Code',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Estate">
        <TableComponents.Search
          dataIndex="seller.estateName"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'seller.estateName',
    align: 'center',
    render: (_, data) => (
      <TableComponents.ClickableCell
        data={data}
        dataIndex="seller.estateName"
        dataKey="seller"
        modalTitle="Seller info"
        columns={sellerColumns}
      />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="ORD STATUS">
        <TableComponents.Search
          dataIndex="orderStatus"
          storeName="ordersStore"
          type="select"
          selectOptions={selectOrderStatuses}
        />
      </TableComponents.Title>
    ),
    dataIndex: 'orderStatus',
    width: 150,
    align: 'center',
  },
  {
    title: 'Alert',
    dataIndex: 'alert',
    align: 'center',
    render: (_, { alert }) => <TableComponents.AlertStatusCell alert={alert} />,
  },
  {
    title: () => (
      <TableComponents.Title title="PAYMNT ST">
        <TableComponents.Search
          dataIndex="paymentStatus"
          storeName="ordersStore"
          type="select"
          selectOptions={paymentStatuses}
        />
      </TableComponents.Title>
    ),
    dataIndex: 'paymentStatus',
    width: 150,
    align: 'center',
    render: (_, { paymentStatus }) => (
      <TableComponents.PaymentStatusCell status={paymentStatus} />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="Buyer">
        <TableComponents.Search
          dataIndex="buyer.fullName"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'buyer.fullName',
    align: 'center',
    render: (_, data) => (
      <TableComponents.ClickableCell
        data={data}
        dataIndex="buyer.fullName"
        dataKey="buyer"
        modalTitle="Buyer info"
        columns={buyerColumns}
      />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="DEST">
        <TableComponents.Search
          dataIndex="buyer.iso2Code"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'buyer.iso2Code',
    align: 'center',
  },
  {
    title: 'UPS ST',
    dataIndex: 'upsStatus',
    width: 150,
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="TRCKN ID">
        <TableComponents.Search
          dataIndex="trackingNumbers"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'trackingNumbers',
    align: 'center',
    render: (_, { trackingNumbers }) => (
      <TableComponents.TrackingNumberCell trackingNumbers={trackingNumbers} />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="PRN">
        <TableComponents.Search
          dataIndex="pickupRequestNumber"
          storeName="ordersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'pickupRequestNumber',
    align: 'center',
    width: 250,
  },
  {
    title: 'TOTAL',
    dataIndex: 'totalPrice',
    render: (_, { totalPrice }) => normalizeMoneyValue(totalPrice),
    align: 'center',
  },
  {
    title: 'TOTALWTHOUTDIS',
    dataIndex: 'totalWithoutDiscount',
    render: (_, { priceWithoutDiscount }) =>
      normalizeMoneyValue(priceWithoutDiscount),
    align: 'center',
  },
  {
    title: 'COUPON DSCNT',
    dataIndex: 'discount',
    render: (_, { discount }) => normalizeMoneyValue(discount),
    align: 'center',
  },
  {
    title: 'FEE',
    dataIndex: 'fee',
    // render: (_, { fee }) => normalizeMoneyValue(fee),
    render: (_, { totalPrice, payout }) =>
      payout ? normalizeMoneyValue(totalPrice - payout) : '-',
    align: 'center',
  },
  {
    title: 'PYOUT',
    dataIndex: 'payout',
    render: (_, { payout }) =>
      payout ? normalizeMoneyValue(payout) : 'not recovered',
    align: 'center',
  },
  {
    title: 'TRF_ID',
    dataIndex: 'transferId',
    render: (_, { transferId }) => (transferId ? transferId : 'not recovered'),
    align: 'center',
  },
  {
    title: '#TOTAL BTLS',
    dataIndex: 'itemsAmount',
    align: 'center',
  },
  {
    title: '#STILL',
    dataIndex: 'stillAmount',
    render: (_, { products }) =>
      products.reduce(
        (acc, { sparkling, quantity }) => (sparkling ? acc : acc + quantity),
        0
      ),
    align: 'center',
  },
  {
    title: '#SPRKL',
    dataIndex: 'sparklingAmount',
    render: (_, { products }) =>
      products.reduce(
        (acc, { sparkling, quantity }) => (sparkling ? acc + quantity : acc),
        0
      ),
    align: 'center',
  },
  {
    title: 'PLTF',
    dataIndex: 'platform',
    align: 'center',
  },
];

export const tableActions = {
  sendMail: {
    title: 'Send mail',
    inner: () => <p>Do you want to resend the invoice email to the buyer?</p>,
    actions: {
      ok: {
        title: 'Yes, also send email',
        type: 'primary',
        icon: 'envelope',
      },
      cancel: {
        title: 'No, only download',
        type: 'link',
        icon: 'stop',
      },
    },
  },
  buyerAndDeliveryInfo: {
    title: 'Delivery info',
    type: 'link',
    icon: 'eye',
  },
  cancelOrderButton: {
    title: 'Cancel',
    type: 'link',
    icon: 'stop',
  },
  confirmOrderButton: {
    title: 'Confirm',
    type: 'link',
    icon: 'check',
  },
  downloadDocumentsButton: {
    title: 'Download documents',
    type: 'link',
    icon: 'download',
  },
  requestPickUpButton: {
    title: 'Request pick-up',
    type: 'link',
    icon: 'edit',
  },
  orderedProducts: {
    title: 'Order info',
    type: 'link',
    icon: 'eye',
  },
  sellerInfo: {
    title: 'Seller info',
    type: 'link',
    icon: 'eye',
  },
  orderNotes: {
    title: 'Save',
    type: 'primary',
    icon: 'save',
  },
  generateInvoicesButton: {
    title: 'Generate invoices and download',
    type: 'link',
    icon: 'download',
  },
};

export const modals = {
  cancel: {
    title: 'Cancel order',
    inner: order => (
      <p>
        Do you really want to cancel <b>{order}</b> order?
      </p>
    ),
  },
  confirm: {
    title: 'Confirm order on customer behalf',
    inner: order => (
      <p>
        Do you want to confirm <b>{order}</b> order?
      </p>
    ),
  },
};

export const OrderStatus = {
  NEW: 'new',
  IN_PROGRESS: 'in progress',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
};

export const selectOrderStatuses = [
  { value: OrderStatus.NEW, title: 'New' },
  { value: OrderStatus.IN_PROGRESS, title: 'In progress' },
  { value: OrderStatus.DELIVERED, title: 'Delivered' },
  { value: OrderStatus.CANCELED, title: 'Canceled' },
];

export const optionsCSV = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  filename: 'orders',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export const paymentStatuses = [
  { value: 'paid', title: 'Paid' },
  { value: 'failed', title: 'Failed' },
  { value: 'customerbehalf', title: 'On Customer Behalf' },
  { value: 'customerbehalfconfirmed', title: 'On Customer Behalf Confirmed' },
  { value: 'customerbehalfpaid', title: 'On Customer Behalf Paid' },
];
