import Login from './pages/login';
import Sellers from './pages/sellers';
import Buyers from './pages/buyers';
import Products from './pages/products';
import Orders from './pages/orders';
import Reports from './pages/reports';
import Discounts from './pages/discounts';
import Alerts from './pages/alerts';

export const login = {
  path: '/login',
  component: Login,
};

export default [
  {
    path: '/estates',
    exact: true,
    component: Sellers,
  },
  {
    path: '/buyers',
    exact: true,
    component: Buyers,
  },
  {
    path: '/products',
    exact: true,
    component: Products,
  },
  {
    path: '/orders',
    exact: true,
    component: Orders,
  },
  {
    path: '/reports',
    exact: true,
    component: Reports,
  },
  {
    path: '/promo',
    exact: true,
    component: Discounts,
  },
  {
    path: '/alerts',
    exact: true,
    component: Alerts,
  },
];
