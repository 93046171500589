import React from 'react';
import { List } from 'antd';

import { TableComponents } from '../../components/table';
import { promoStatuses } from '../sellers/settings';

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="Code">
        <TableComponents.Search dataIndex="code" storeName="discountsStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Discount status">
        <TableComponents.Search
          dataIndex="isActive"
          storeName="discountsStore"
          type="select"
          selectOptions={promoStatuses}
        />
      </TableComponents.Title>
    ),
    width: 200,
    align: 'center',
    render: (_, { isActive }) => (
      <TableComponents.PromoStatusCell hasActivePromo={isActive} />
    ),
  },
  {
    title: () => (
      <TableComponents.Title title="Owner">
        <TableComponents.Search
          dataIndex="owner"
          storeName="discountsStore"
          type="select"
          selectOptions={DISCOUNT_OWNER_OPTS}
        />
      </TableComponents.Title>
    ),
    dataIndex: 'owner',
    align: 'center',
  },
  {
    title: 'Promo Type',
    dataIndex: 'promoType',
    align: 'center',
    render: (_, { promoType }) => (
      <List split={false}>
        {promoType &&
          promoType.map((type, index) => (
            <List.Item key={index}>{type}</List.Item>
          ))}
      </List>
    ),
  },
  {
    title: 'Avelyn share',
    dataIndex: 'marketplaceAmount',
    align: 'center',
  },
  {
    title: 'Seller share',
    dataIndex: 'sellerAmount',
    align: 'center',
  },
  {
    title: 'Expiration date',
    dataIndex: 'expirationDate',
    align: 'center',
  },
];

export const tableActions = {
  deleteButton: {
    title: 'Delete',
    type: 'link',
    icon: 'delete',
  },
  updateDiscountButton: {
    title: 'Edit',
    type: 'link',
    icon: 'edit',
  },
};

export const modals = {
  delete: {
    title: 'Delete discount',
    inner: discount => (
      <p>
        Do you really want to delete <b>{discount}</b> code?
      </p>
    ),
  },
  create: {
    title: 'Create discount',
  },
  edit: {
    title: 'Edit discount',
  },
};

export const DISCOUNT_OWNER_OPTS = [
  { value: 'avelyn', title: 'Avelyn' },
  { value: 'seller', title: 'Seller' },
];

export const DISCOUNT_OWNER = { avelyn: 'avelyn', seller: 'seller' };
