import { action } from 'mobx';

class Sitemap {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound onExportSitemap(body) {
    const blob = new Blob([body], { type: 'text/xml' });
    const url = window.URL.createObjectURL(blob);
    const fileTag = document.createElement('a');
    fileTag.setAttribute('hidden', true);
    fileTag.setAttribute('href', url);
    fileTag.setAttribute('download', 'sitemap.xml');
    document.body.appendChild(fileTag);
    fileTag.click();
    document.body.removeChild(fileTag);
  }

  @action.bound async exportSitemap() {
    const { url, method } = this.rootStore.urls.sitemap.getSitemapExport;
    return this.rootStore.makeRequest(
      this.onExportSitemap,
      method,
      url,
      {},
      {},
      'blob'
    );
  }
}

export default Sitemap;
