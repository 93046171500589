import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Table } from '../../components/table';
import { columns, tableActions, modals } from './settings';
import { compose } from '../../services/helpers';
import Button from '../../components/button';
import Modal from '../../components/modal';
import { useModal } from '../../common';

const Buyers = ({ buyersStore }) => {
  const {
    buyers,
    buyer,
    fetchAll,
    findOne,
    delete: deleteBuyer,
    resetSearch,
    total,
    changePagination,
    exportCSV,
  } = buyersStore;
  const [
    isDeleteModalShown,
    onSetIsDeleteModalShown,
    onDeleteOkAction,
    onDeleteCancelAction,
  ] = useModal();

  useEffect(() => {
    const onMount = async () => {
      await fetchAll();
    };

    onMount();

    return resetSearch;
  }, [fetchAll, resetSearch]);

  const renderActions = row => (
    <>
      <Button
        type={tableActions.deleteButton.type}
        icon={tableActions.deleteButton.icon}
        color="#ff7875"
        onClick={onSetIsDeleteModalShown(findOne(row.id))}
      >
        {tableActions.deleteButton.title}
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title={modals.delete.title}
        visible={isDeleteModalShown}
        width="30%"
        destroyOnClose
        onOk={onDeleteOkAction(deleteBuyer(buyer.id))}
        onCancel={onDeleteCancelAction()}
      >
        {modals.delete.inner(buyer.fullName || buyer.email)}
      </Modal>
      <Table
        columns={columns}
        data={buyers}
        renderActions={renderActions}
        pagination
        paginationTotal={total}
        onChangePagination={changePagination}
        onCSVExport={exportCSV}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    buyersStore: rootStore.buyersStore,
  })),
  observer
)(Buyers);
