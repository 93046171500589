import React from 'react';

import { StyledAlertStatusCell } from './styled';

const AlertStatusCell = ({ alert }) => (
  <StyledAlertStatusCell alert={alert}>
    {alert}
  </StyledAlertStatusCell>
);

export default AlertStatusCell;
