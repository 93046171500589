import React from 'react';

import { TableComponents } from '../../components/table';

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="First name">
        <TableComponents.Search
          dataIndex="profile.firstName"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.firstName',
  },
  {
    title: () => (
      <TableComponents.Title title="Last name">
        <TableComponents.Search
          dataIndex="profile.lastName"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.lastName',
  },
  {
    title: () => (
      <TableComponents.Title title="Email">
        <TableComponents.Search dataIndex="email" storeName="buyersStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'email',
  },
  {
    title: () => (
      <TableComponents.Title title="Country">
        <TableComponents.Search
          dataIndex="profile.addresseeCountry.name"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.addresseeCountry.name',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="City">
        <TableComponents.Search
          dataIndex="profile.addresseeCity"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.addresseeCity',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Postal code">
        <TableComponents.Search
          dataIndex="profile.addresseePostalCode"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.addresseePostalCode',
    align: 'center',
  },
  {
    title: () => (
      <TableComponents.Title title="Address">
        <TableComponents.Search
          dataIndex="profile.address"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.address',
  },
  {
    title: () => (
      <TableComponents.Title title="Phone">
        <TableComponents.Search
          dataIndex="profile.phoneNumber"
          storeName="buyersStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.phoneNumber',
  },
  {
    title: () => (
      <TableComponents.Title title="Condition">
        <TableComponents.Search dataIndex="condition" storeName="buyersStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'condition',
  },
  {
    title: 'Date of registration',
    dataIndex: 'createdAt',
    align: 'center',
  },
];

export const tableActions = {
  deleteButton: {
    title: 'Delete',
    type: 'link',
    icon: 'delete',
  },
};

export const modals = {
  delete: {
    title: 'Delete buyer',
    inner: buyer => (
      <p>
        Do you really want to delete <b>{buyer}</b> buyer?
      </p>
    ),
  },
};

export const optionsCSV = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  filename: 'buyers',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
