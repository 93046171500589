import React from 'react';

import { placeholder } from './settings';
import { StyledLogoWrapper, StyledPlaceholder } from './styled';

const Logo = ({ size = '' }) => (
  <StyledLogoWrapper size={size}>
    <StyledPlaceholder size={size}>{placeholder}</StyledPlaceholder>
  </StyledLogoWrapper>
);

export default Logo;
