import { action, observable, computed } from 'mobx';
import {round} from 'lodash';
import { normalizeProductData } from '../services/helpers';
import Product from '../models/Product';
export const WINEJUMP_FEE = {
  currency: "EUR",
  amount: 1.5,
  percent: 20 / 100,
  max: 10,
  label: "Avelyn fee",
};

export function addAvelynFee(price) {
  let fee = WINEJUMP_FEE.percent * parseFloat(price);
  fee = fee < WINEJUMP_FEE.amount ? WINEJUMP_FEE.amount : fee;
  fee = fee > WINEJUMP_FEE.max ? WINEJUMP_FEE.max : fee;
  const total = round(price + fee, 2);
  return total;
}

function subtractAvelynFee(price) {
  let fee = 0;
  if (price / 6 < WINEJUMP_FEE.amount) fee = WINEJUMP_FEE.amount;
  else if (price / 6 > WINEJUMP_FEE.max) fee = WINEJUMP_FEE.max;
  else fee = price / 6;
  const total = round(price - fee, 2);
  return total;
}

class Products {
  @observable rawProducts = [];
  @observable rawProduct = {};
  @observable searchValue = '';
  @observable searchIn = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawProduct = new Product({}, rootStore);
  }

  @computed
  get products() {
    return this.rawProducts;
  }

  @computed
  get product() {
    return this.rawProduct;
  }

  @action.bound resetSelectedProduct() {
    this.rawProduct = new Product({}, this.rootStore);
  }

  @action.bound onFetchAll(data) {
    this.rawProducts = data.raw.map(p => new Product(p, this.rootStore));
    this.total = data.total;
    this.ratingSystem = data.ratingSystem;
  }

  @action.bound async fetchAll() {
    const {
      getAll: { method, url },
    } = this.rootStore.urls.products;

    return await this.rootStore.makeRequest(this.onFetchAll, method, url(15));
  }

  @action.bound findOne(id) {
    return this.boundedFindOne.bind(this, id);
  }

  @action.bound async boundedFindOne(id) {
    this.rawProduct = this.rawProducts.find(e => e.id === id);
  }

  @action.bound async create(rawData) {
    const {
      create: { method, url },
    } = this.rootStore.urls.products;

    const parsedData = normalizeProductData('create', { ...rawData,images: this.rawProduct.images });
    parsedData.price = addAvelynFee(parsedData.price);
    const response = await this.rootStore.makeRequest(
      this.onCreate,
      method,
      url,
      parsedData
    );
    if(response.price){
      response.price = subtractAvelynFee(response.price);
      return response;
    }
  }

  @action.bound onCreate(data) {
    this.rawProducts = [new Product(data, this.rootStore), ...this.rawProducts];
    this.rootStore.productsStore.resetSelectedProduct();
  }

  @action.bound delete(id) {
    return this.boundedDelete.bind(this, id);
  }

  @action.bound onDelete(_, { id }) {
    this.rawProducts = this.rawProducts.filter(p => p.id !== id);
  }

  @action.bound async boundedDelete(id) {
    const {
      delete: { method, url },
    } = this.rootStore.urls.products;

    return await this.rootStore.makeRequest(
      this.onDelete,
      method,
      `${url}/${id}`,
      { id }
    );
  }

  @action.bound async search(value, field) {
    this.searchValue = `${value}`.toLowerCase();
    this.searchIn = field;
    return await this.searchProducts(this.searchIn, this.searchValue);
  }

  @action.bound async resetSearch() {
    this.searchValue = '';
    this.searchIn = '';
    return await this.fetchAll();
  }

  @action.bound async update(rawData) {
    const {
      update: { method, url },
    } = this.rootStore.urls.products;
    const parsedData = normalizeProductData('update', { ...rawData, id: this.rawProduct.id, images: this.rawProduct.images });
    parsedData.price = addAvelynFee(parsedData.price);
    const response = await this.rootStore.makeRequest(
      this.onUpdate,
      method,
      url,
      parsedData
    );
    console.log(response)
    return response;
  }

  @action.bound onUpdate(responseData) {
    this.rawProducts = this.rawProducts.map(p => {
      if (p.id === responseData.id) {
        if(responseData.price){
          responseData.price = subtractAvelynFee(responseData.price);
        }
        return new Product(responseData, this.rootStore);
      }
      return p;
    });

    this.resetSelectedProduct();
  }

  @action.bound onChangePagination(data) {
    this.rawProducts = data.raw.map(p => new Product(p, this.rootStore));
    this.total = data.total;
    this.ratingSystem = data.ratingSystem;
  }

  @action.bound async changePagination(page, pageSize) {
    if (!this.searchValue || !this.searchIn) {
      const { method, url } = this.rootStore.urls.products.getPageData;
      return await this.rootStore.makeRequest(
        this.onChangePagination,
        method,
        url(page, pageSize)
      );
    }

    return await this.searchProducts(this.searchIn, this.searchValue, page);
  }

  async searchProducts(searchIn, searchValue, page) {
    const { method, url } = this.rootStore.urls.products.search;
    const limit = 15;
    return await this.rootStore.makeRequest(
      this.onFetchAll,
      method,
      url(searchIn, searchValue, limit, page)
    );
  }
}

export default Products;
