import React from 'react';

const Title = ({ title, children }) => (
  <div>
    <span>{title.toUpperCase()}</span>
    {children}
  </div>
);

export default Title;
