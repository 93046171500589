export default {
  login: {
    method: 'post',
    url: 'api/auth/login',
  },
  users: {
    getAllBuyers: { method: 'get', url: limit => `api/buyers?limit=${limit}` },
    getAllEstates: {
      method: 'get',
      url: (limit, dateFrom, dateTo) =>
        `api/sellers?limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    },
    getBuyersExport: { method: 'get', url: 'api/buyers/export' },
    getSellersExport: {
      method: 'get',
      url: (dateFrom, dateTo) => `api/sellers/export/${dateFrom}/${dateTo}`,
    },
    getWineriesExport: {
      method: 'get',
      url: () => `api/wineries`,
    },
    getBuyersPageData: {
      method: 'get',
      url: (page, limit) => `api/buyers?page=${page}&limit=${limit}`,
    },
    getSellersPageData: {
      method: 'get',
      url: (page, limit) => `api/sellers?page=${page}&limit=${limit}`,
    },
    searchBuyers: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/buyers/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
    searchSellers: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/sellers/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
    create: { method: 'post', url: 'api/sellers' },
    updateProfile: { method: 'put', url: 'api/sellers/profile' },
    updateSubscription: { method: 'post', url: 'api/sellers/subscriptions' },
    updateEmail: { method: 'put', url: 'api/sellers/email' },
    delete: { method: 'del', url: 'api/users' },
    uploadAvatar: { method: 'post', url: 'api/sellers/upload/logo' },
    deleteAvatar: { method: 'del', url: 'api/sellers/upload/logo' },
    uploadBanner: { method: 'post', url: 'api/sellers/upload/banner' },
    deleteBanner: { method: 'del', url: 'api/sellers/upload/banner' },
    uploadPhoto: { method: 'post', url: 'api/sellers/upload/photo' },
    deletePhoto: { method: 'del', url: 'api/sellers/upload/photo' },
    validateEmailForUniqueness: {
      method: 'get',
      url: email => `api/sellers/email/unique?email=${email}`,
    },
  },
  countries: {
    getSellerCountries: { method: 'get', url: 'api/countries/seller' },
    getCountriesToSell: { method: 'get', url: 'api/countries/to-sell' },
  },
  products: {
    getAll: { method: 'get', url: limit => `api/products?limit=${limit}` },
    getGroups: { method: 'get', url: 'api/products/groups' },
    getPageData: {
      method: 'get',
      url: (page, limit) => `api/products?page=${page}&limit=${limit}`,
    },
    search: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/products/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
    create: { method: 'post', url: 'api/products' },
    update: { method: 'put', url: 'api/products' },
    delete: { method: 'del', url: 'api/products' },
    uploadImage: { method: 'post', url: 'api/products/upload/image' },
    deleteImageFromProduct: { method: 'del', url: 'api/products/upload/image' },
    deleteImage: { method: 'del', url: 'api/products/image/upload' },
  },
  orders: {
    getAll: {
      method: 'get',
      url: (limit, dateFrom, dateTo) =>
        `api/orders?limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    },
    getOrdersExport: {
      method: 'get',
      url: (from, to) => `api/orders/export/${from}/${to}`,
    },
    getPageData: {
      method: 'get',
      url: (page, limit, dateFrom, dateTo) =>
        `api/orders?page=${page}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    },
    search: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/orders/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
    updateOrderNotes: {
      method: 'put',
      url: orderId => `api/orders/${orderId}/notes`,
    },
    cancelOrder: {
      method: 'post',
      url: orderId => `api/orders/${orderId}/cancel`,
    },
    confirmOrder: {
      method: 'post',
      url: orderId => `api/orders/${orderId}/confirm`,
    },
    downloadOrderDocuments: {
      method: 'get',
      url: orderId => `api/orders/${orderId}/download`,
    },
    setOrderPickUp: {
      method: 'post',
      url: orderId => `api/orders/${orderId}/pickup`,
    },
    generateInvoices: {
      method: 'get',
      url: (orderId, sendMail) =>
        `api/orders/${orderId}/invoices/generate?sendEmail=${sendMail}`,
    },
  },
  reports: {
    getAll: {
      method: 'get',
      url: (destination, from, to) =>
        `api/reports/${destination}/country/${from}/${to}`,
    },
  },
  sitemap: {
    getSitemapExport: { method: 'get', url: 'sitemap.xml' },
  },
  discounts: {
    getAll: {
      method: 'get',
      url: (limit = 15) => `api/discounts?limit=${limit}`,
    },
    createDiscount: { method: 'post', url: 'api/discounts' },
    deleteDiscount: {
      method: 'del',
      url: discountId => `api/discounts/${discountId}`,
    },
    getDiscountsPageData: {
      method: 'get',
      url: (page, limit) => `api/discounts?page=${page}&limit=${limit}`,
    },
    updateDiscount: {
      method: 'put',
      url: discountId => `api/discounts/${discountId}`,
    },
    searchDiscounts: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/discounts/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
    switchUserDiscount: {
      method: 'post',
      url: (userDiscountId, isActive) =>
        `api/discounts/switchUserDiscount?id=${userDiscountId}&isActive=${isActive}`,
    },
  },
  alerts: {
    getAll: {
      method: 'get',
      url: (limit = 15) => `api/orders/alerts?limit=${limit}`,
    },
    getPageData: {
      method: 'get',
      url: (page, limit) => `api/orders/alerts?page=${page}&limit=${limit}`,
    },
    search: {
      method: 'get',
      url: (searchIn, searchValue, limit, page = 1) =>
        `api/orders/alerts/search?searchIn=${searchIn}&searchValue=${searchValue}&limit=${limit}&page=${page}`,
    },
  },
};
