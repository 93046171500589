import { observable, action, computed } from 'mobx';

import { ls, api } from '../services';

class Auth {
  @observable data = { admin: {}, token: '' };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get role() {
    return this.data.admin.role || '';
  }

  @action.bound set(data) {
    if (!data) return;
    this.data = data;
  }

  @action.bound async onLoginSuccess(data) {
    this.set(data);
    await ls.save('user', data);
  }

  @action.bound async login(data) {
    const { method, url } = this.rootStore.urls.login;

    return await this.rootStore.makeRequest(
      this.onLoginSuccess,
      method,
      url,
      data
    );
  }

  async logout() {
    await api.logout();
  }
}

export default Auth;
