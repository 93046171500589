import React from 'react';

import { TableComponents } from '../../components/table';

export const columns = [
  {
    title: () => (
      <TableComponents.Title title="First name">
        <TableComponents.Search
          dataIndex="profile.firstName"
          storeName="estatesStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.firstName',
  },
  {
    title: () => (
      <TableComponents.Title title="Last name">
        <TableComponents.Search
          dataIndex="profile.lastName"
          storeName="estatesStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.lastName',
  },
  {
    title: () => (
      <TableComponents.Title title="Estate name">
        <TableComponents.Search
          dataIndex="profile.estateName"
          storeName="estatesStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.estateName',
  },
  {
    title: () => (
      <TableComponents.Title title="Phone number">
        <TableComponents.Search
          dataIndex="profile.phoneNumber"
          storeName="estatesStore"
        />
      </TableComponents.Title>
    ),
    dataIndex: 'profile.phoneNumber',
  },
  {
    title: () => (
      <TableComponents.Title title="Email">
        <TableComponents.Search dataIndex="email" storeName="estatesStore" />
      </TableComponents.Title>
    ),
    dataIndex: 'email',
  },
  {
    title: () => (
      <TableComponents.Title title="Promo status">
        <TableComponents.Search
          dataIndex="promo"
          storeName="estatesStore"
          type="select"
          selectOptions={promoStatuses}
        />
      </TableComponents.Title>
    ),
    width: 200,
    align: 'center',
    render: (_, { promo }) => {
      const hasActivePromo = promo && promo.isActive;
      return (
        <TableComponents.PromoStatusCell hasActivePromo={hasActivePromo} />
      );
    },
  },
  {
    title: () => (
      <TableComponents.Title title="MRP Subscription"></TableComponents.Title>
    ),
    width: 250,
    align: 'center',
    render: ({ id, profile: { subscription } }) => {
      return (
        <TableComponents.SubscriptionsCell
          subscription={subscription}
          userId={id}
          name={'subscription'}
        />
      );
    },
  },
  {
    title: () => (
      <TableComponents.Title title="SP Subscription"></TableComponents.Title>
    ),
    width: 250,
    align: 'center',
    render: (_, { id, profile: { sp_subscription } }) => {
      return (
        <TableComponents.SubscriptionsCell
          subscription={sp_subscription}
          userId={id}
          name={'sp_subscription'}
        />
      );
    },
  },
  {
    title: () => (
      <TableComponents.Title title="WC Subscription"></TableComponents.Title>
    ),
    width: 250,
    align: 'center',
    render: (_, { id, profile: { wc_subscription } }) => {
      return (
        <TableComponents.SubscriptionsCell
          subscription={wc_subscription}
          userId={id}
          name={'wc_subscription'}
        />
      );
    },
  },
  {
    title: 'Has Stripe account',
    render: (_, { profile }) => (profile.stripeAccountId ? 'Yes' : 'No'),
    align: 'center',
  },
  {
    title: 'Activated',
    render: (_, { profile }) => (profile.activated ? 'Yes' : 'No'),
    align: 'center',
  },
  {
    title: 'Date of registration',
    dataIndex: 'createdAt',
    align: 'center',
  },
];

export const tableActions = {
  copyTastingRoomURL: {
    title: 'Copy sales point URL',
    type: 'link',
    icon: 'copy',
  },
  copyWebConnectURL: {
    title: 'Copy webshop connect URL',
    type: 'link',
    icon: 'copy',
  },
  manageSubsctions: {
    title: 'Manage subscriptions',
    type: 'link',
    icon: 'edit',
  },
  updateEmailButton: {
    title: 'Edit email',
    type: 'link',
    icon: 'edit',
  },
  updateProfileButton: {
    title: 'Edit profile',
    type: 'link',
    icon: 'edit',
  },
  deleteButton: {
    title: 'Delete',
    type: 'link',
    icon: 'delete',
  },
};

export const modals = {
  create: {
    title: 'Create estate',
  },
  updateProfile: {
    title: 'Update estate profile',
  },
  updateEmail: {
    title: 'Update estate email',
  },
  delete: {
    title: 'Delete estate',
    inner: estate => (
      <p>
        Do you really want to delete <b>{estate}</b> estate?
      </p>
    ),
  },
  subscriptions: { title: 'Mange subscriptions' },
};

export const optionsCSV = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  filename: 'sellers',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export const promoStatuses = [
  { value: 'true', title: 'Active' },
  { value: 'false', title: 'Inactive' },
];
