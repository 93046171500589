import styled from 'styled-components';

export const StyledPreloader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;
