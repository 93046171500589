import React from 'react';
import { Form, Input, Select, Row, Col, Switch, Icon } from 'antd';
import { observer } from 'mobx-react';

import { compose } from '../../../services/helpers';
import Button from '../../button';
import { productForm } from './settings';

const { Option } = Select;
const {
  ratingActiveField,
  ratingTypeField,
  ratingScoreField,
  ratingReferenceField,
  deleteButton,
  addRatingButton,
  ratingStatusField,
} = productForm;

const ProductRatingForm = ({ product, form, ratingSystem }) => {
  return (
    <>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={3}>
          <Form.Item label={addRatingButton.label} labelAlign="left">
            <Button
              type={addRatingButton.type}
              icon={addRatingButton.icon}
              size={addRatingButton.size}
              shape={addRatingButton.shape}
              style={addRatingButton.styles}
              onClick={product.addRating}
            ></Button>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label={ratingActiveField.label}>
            {form.getFieldDecorator(ratingActiveField.name)(
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked={product.ratingActive}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label={'Set rating status'}>
            {form.getFieldDecorator(`${ratingStatusField.name}`, {
              rules: ratingStatusField.rules,
              initialValue: ratingStatusField.initialValue(
                product.ratingStatus
              ),
            })(
              <Select>
                {ratingStatusField.options.map(({ value, title }) => (
                  <Option key={value} value={value}>
                    {title}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      {product.ratings.map((rate, index) => {
        return (
          <Row type="flex" justify="space-between" align="middle">
            <Col span={6}>
              <Form.Item label={ratingTypeField.label}>
                {form.getFieldDecorator(`${ratingTypeField.name}-${index}`, {
                  rules: ratingTypeField.rules(ratingSystem),
                  initialValue: ratingTypeField.initialValue(ratingSystem, [
                    { id: rate.systemId },
                  ]),
                  getValueProps: value => value,
                })(
                  <Select
                    value={rate.systemId || ''}
                    onChange={product.changeRatingType(index)}
                  >
                    {ratingSystem.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={ratingScoreField.label}>
                {form.getFieldDecorator(`${ratingScoreField.name}-${index}`, {
                  rules: ratingScoreField.rules(ratingSystem, rate),
                  initialValue: ratingScoreField.initialValue(
                    ratingSystem,
                    rate
                  ),
                  getValueProps: value => value,
                })(
                  <Select
                    onChange={product.changeScores(index)}
                    value={rate.scoreId || ''}
                  >
                    {ratingScoreField
                      .getSystemScores(ratingSystem, rate)
                      .map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={ratingReferenceField.label}>
                {form.getFieldDecorator(
                  `${ratingReferenceField.name}-${index}`,
                  {
                    rules: ratingReferenceField.rules,
                    initialValue: ratingReferenceField.initialValue(rate),
                    getValueProps: value => value,
                  }
                )(
                  <Input
                    value={rate.reference}
                    onChange={product.changeRatingReference(index)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={0.2}>
              <div>
                <Button
                  type={deleteButton.type}
                  icon={deleteButton.icon}
                  color="#ff7875"
                  onClick={product.deleteRating(index)}
                ></Button>
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default compose(observer)(ProductRatingForm);
