import React from 'react';
import { Icon } from 'antd';
import { inject, observer } from 'mobx-react';

import { StyledSwitch } from './styled';
import { compose } from '../../../services/helpers';

const SubscriptionsCell = ({ subscription, userId, estatesStore, name }) => {
  const { changeSubscription } = estatesStore;

  return (
    <StyledSwitch
      onChange={changeSubscription(name, userId)}
      checkedChildren={<Icon size="large" type="check" />}
      unCheckedChildren={<Icon type="close" />}
      checked={subscription}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    estatesStore: rootStore.estatesStore,
  })),
  observer
)(SubscriptionsCell);
