import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Layout from '../../layout';
import { compose } from '../../../services/helpers';

const ProtectedRoute = ({ authStore, ...restProps }) => {
  const { component: Page, roles, ...routeProps } = restProps;

  if (!authStore.data.token) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...routeProps}
      render={props => (
        <Layout>
          <Page {...props} />
        </Layout>
      )}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
  })),
  observer
)(ProtectedRoute);
